import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Chip, Icon, IconButton, Switch, Tooltip, Grid, Backdrop, CircularProgress } from '@mui/material';
import { CardImageSlider } from '../../Components/Card/CardImageSlider';
import PhotoDialog from '../../Components/Dialog/PhotoDialog';
import Marquee from "react-fast-marquee";
import api from '../../../api';
import { toast } from 'react-toastify';

export default function PeopleCard(props) {
  const { people, index, filter = {}, setFilter = () => { } } = props;
  let navigate = useNavigate();
  const [playScroll, setPlayScroll] = useState(false);
  const [open, setOpen] = useState(false);

  const deletePeople = (people_id) => {
    api.delete(`people/${people_id}`)
      .then(() => {
        setFilter({ ...filter });
        toast.success('Pessoa deletada com sucesso!');
      })
      .catch(toast.error)
  }

  const setStatus = (people = {}) => {
    api.put(`people/${people.id}/active/${!people.active}`)
      .then(() => {
        setFilter({ ...filter });
        toast.success('Status atualizado')
      })
      .catch(toast.error)
  }

  const onGetImage = (image, id) => {

    setOpen(true)

    let form = new FormData();
    form.append("image", image, image.name);

    api.post(
      `/people/${id}/picture`,
      form,
      {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
    )
      .then(() => {
        toast.success('Foto atualizada com sucesso');
        setFilter({ ...filter });
        setOpen(false);
      })
      .catch(toast.error)
  }

  return (
    <Grid
      item
      key={index}
      xs={6} sm={4} md={3} lg={2} xl={1.5}
      onMouseEnter={() => setPlayScroll(people.name.length > 15 || people.unityGroups.length > 1)}
      onMouseLeave={() => setPlayScroll(false)}
    >

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={() => { setOpen(false) }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <CardImageSlider images={people.pictures.map(m => m.url)}>

        <Grid container style={{ padding: 10, minHeight: 170 }} alignItems={'center'}>

          <Grid item xs={12}>
            <p style={{ fontSize: 13, margin: 0 }}>#{people.id ? people.id.toString().padStart(10, '0') : ''}</p>
          </Grid>

          <Grid item xs={12}>
            <Marquee
              gradient={false}
              play={playScroll}
              speed={35}
              style={{ fontSize: 19 }}
            >
              <Grid container columnSpacing={1}>
                <Grid item xs={12}>
                  {people.name.length > 15 ? ` ${people.name}` : people.name}
                </Grid>

                {people.unityGroups.map((ug, i) =>
                  <Grid item>
                    <Chip label={`${ug.nome_sup ? `${ug.nome_sup} - ` : ''}${ug.name}`} />
                  </Grid>
                )}
                {
                  !people.unityGroups.length &&
                  <Grid item>
                    <Chip label={'Nenhum'} />
                  </Grid>
                }

              </Grid>
            </Marquee>
          </Grid>

          <Grid container justifyContent={'flex-end'} alignItems={'center'} style={{ display: 'Flex', justifyContent: 'flex-end' }}>

            <Grid item xs={12} >
              <Grid container justifyContent={'flex-end'} alignItems={'center'} style={{ paddingTop: 3 }}>

                <Grid item >
                  <Tooltip title={people.sync_status}>
                    <Icon style={{
                      marginTop: 4, color: people.sync_status == 'Sincronizado' ? 'green' :
                        people.sync_status == 'Erro' ? 'red' :
                          'gray'
                    }}>{
                        people.sync_status == 'Sincronizado' ? 'mobile_friendly' :
                          people.sync_status == 'Erro' ? 'sync_problem' :
                            'sync_disabled'
                      }</Icon>
                  </Tooltip>
                </Grid>

                <Grid item>
                  <Switch checked={people.active} onChange={() => { setStatus(people) }} />
                </Grid>

                <Grid item >
                  <PhotoDialog onGetImage={(image) => {
                    onGetImage(image, people.id)
                  }} disabled={people.pictures.length >= 5} />
                </Grid>

                <Grid item>
                  <IconButton onClick={() => { navigate(`/admin/people/${people.id}`); }}>
                    <Tooltip title="Editar" arrow>
                      <Icon>edit</Icon>
                    </Tooltip>
                  </IconButton>
                </Grid>

              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </CardImageSlider>
    </Grid>
  )
}