import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@mui/material';
import api from '../../../api';

export default function AccountMenu(props) {
  const { event } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [admin, setAdmin] = React.useState(false);

  React.useEffect(() => {
    if (event) {
      setAnchorEl(event.currentTarget);

      api.get('user/admin')
        .then((ret) => {
          setAdmin(ret)
        })
        .catch((err) => { console.log(err) });

    }
  }, [event])

  const handleClose = () => {
    setAnchorEl(null);
  };

  const userMenu = [
    {
      id: 'profile',
      label: 'Perfil',
      link: '/admin/settings/profile',
      icon: 'account_circle'
    },
    {
      id: 'users',
      admin: true,
      label: 'Usuários',
      link: '/admin/settings/users',
      icon: 'people'
    },
    {
      id: 'advanced',
      admin: true,
      label: 'Avançadas',
      link: '/admin/settings/advanced',
      icon: 'warning'
    },
    {
      id: 'log',
      admin: true,
      label: 'Logs',
      link: '/admin/settings/logs',
      icon: 'terminal'
    },
    {
      id: '-'
    },
    {
      id: 'logout',
      label: 'Sair',
      link: '/logout',
      icon: 'logout'
    },
  ]


  const navigate = useNavigate();

  const handleClick = (e) => {
    const selectedMenu = userMenu.find(f => f.id == e.target.id);
    if (selectedMenu) navigate(selectedMenu.link);

    setAnchorEl(null);
  }

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClick}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom'}}
    >
      {
        userMenu.filter(f => !f.admin || f.admin == admin).map((row, index) => {
          return row.id == '-' ? <Divider key={index} style={{ margin: 5 }} /> :
            <MenuItem id={row.id} key={index}>
              <ListItemIcon id={row.id}>
                <Icon fontSize="small" id={row.id}>{row.icon}</Icon>
              </ListItemIcon>
              {row.label}
            </MenuItem>

        })
      }
    </Menu>
  );
}
