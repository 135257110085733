import React, { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Copyright from './../Components/UI/Copyright';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from '../../api';
import SHA256 from 'crypto-js/sha256';
import { CircularProgress, Grid, Icon, IconButton } from '@mui/material';
import { toast } from 'react-toastify';
import TextField from '../Components/Form/TextField';
import Button from '../Components/Form/Button';
import { allowSubmit } from '../../utils/form';

export default function Login() {

  let navigate = useNavigate();
  const [passwordData, setPasswordData] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    api.post('auth/confirmreset', { token: searchParams.get("token"), password: (SHA256(passwordData.password)).toString() })
      .then(ret => {
        toast.success('Senha atualizada com sucesso.')
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        toast.error(err);
      })

  };

  const requiredFields = [
    { id: 'password', message: 'Por favor preencha a senha' },
    {
      id: 'rePassword', message: 'As senhas estão diferentes', rule: (value, formData) => {
        return formData.password == formData.rePassword
      }
    }
  ]

  return (

    <Container component="main" maxWidth="xs" >
      <CssBaseline />
      <Box
        sx={{
          marginTop: 15,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >

        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate sx={{ mt: 1 }}
        >

          <Grid
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              background: '#f9f9f9',
              padding: 50,
              paddingBottom: 85,
              borderRadius: 20,
              boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
            }}>

            <img
              style={{ height: 85, marginBottom: 5, transform: 'rotate(-80deg)' }}
              src={'https://cdn-icons-png.flaticon.com/512/4192/4192711.png'}
              alt={'logo'}
            />


            <p style={{
              color: '#202020',
              fontSize: 24,
              fontWeight: '500',
              marginBottom: 10,
              marginTop: 0
            }}>Definir nova senha</p>

            <TextField
              autoFocus
              requiredFields={requiredFields}
              id="password"
              type='password'
              label="Senha"
              name="password"
              value={passwordData.password || ''}
              onChange={e => setPasswordData({ ...passwordData, password: e.target.value })}
            />

            <TextField
              style={{ width: 350 }}
              requiredFields={requiredFields}
              id="rePassword"
              type='password'
              label="Confirmar Senha"
              name="rePassword"
              value={passwordData.rePassword || ''}
              onChange={e => setPasswordData({ ...passwordData, rePassword: e.target.value })}
            />

            <Button
              type="submit"
              fullWidth
              size='default'
              variant="contained"
              disabled={loading || !allowSubmit(requiredFields, passwordData)}
              sx={{ mt: 1, mb: 2 }}
              title={<>Resetar Senha
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
              </>
              }
            />
            <IconButton
              onClick={() => { navigate('/login') }}
              style={{
                borderRadius: 10,
                paddingTop: 0,
                paddingBottom: 0,
                paddingRight: 15,
                paddingLeft: 10,
                transition: '1s'
              }}>

              <Icon >arrow_back</Icon>
              <p style={{ fontSize: 15, fontWeight: 'bold', marginLeft: 7 }}>Retornar ao Login</p>
            </IconButton>
            <Copyright />
          </Grid>
        </Box>
      </Box>

    </Container>
  );
}
