import * as React from 'react';
import { styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@mui/material';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RowCollapse(props) {
  const { open = false, children, collapsed } = props;

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };


  React.useEffect(() => {
    if (open != expanded) setExpanded(open)
  }, [open])

  return (
    <Grid container alignItems={'center'}>
      <Grid item xs>
        {children}
      </Grid>
      <Grid item>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </Grid>

      <Grid item xs={12}>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {collapsed}
        </Collapse>

      </Grid>
    </Grid>
  );
}