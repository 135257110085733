import * as React from 'react';
import { TextField as MuiTextField } from '@mui/material';
import { isErrored } from '../../../utils/form';

export default function TextField(props) {
  const { value, id, requiredFields = [], formData = {}} = props;

  const requiredField = requiredFields.find(f => f.id == id);

  const error = isErrored(requiredField, value, formData);

  const errorProps = { 
    error, 
    required: requiredField != undefined, 
    helperText: error ? requiredField.message : '' 
  };

  return (
    <MuiTextField
      margin="normal"
      size='small'
      fullWidth
      style={{minWidth: 150}}
      // InputLabelProps={{ 
      //   shrink: true,
      // }}
      inputProps={{ maxLength: 50 }}
      {...props}
      {...errorProps}
    />
  );
}