import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { Divider, Grid, Hidden, Icon, Paper } from '@mui/material';
import SettingsUsersPage from '../User/UserPage';
import UserDetail from '../User/UserDetail';
import api from '../../../api';
import ConfigPage from '../Config/ConfigPage';
import { useNavigate } from 'react-router-dom';
import Logs from '../Logs/Logs';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>{children}</>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
    style: {
      textTransform: 'none'
    },

  };
}

export default function SettingsPage() {
  const tabs = [
    {
      title: 'Perfil',
      link: 'profile',
      icon: 'account_circle',
      child: <UserDetail userId={JSON.parse(localStorage.getItem('user')).id} profile />
    },
    {
      title: 'Usuários',
      link: 'users',
      icon: 'people',
      admin: true,
      child: <SettingsUsersPage />
    },
    {
      title: 'Avançadas',
      link: 'advanced',
      icon: 'warning',
      admin: true,
      child: <ConfigPage />
    },
    {
      title: 'Logs',
      link: 'logs',
      icon: 'terminal',
      admin: true,
      child: <Logs />
    }
  ]

  const getActivePage = () => {
    return tabs.map(m => m.link).indexOf(window.location.pathname.split('/')[3])
  }

  const [activeTab, setActiveTab] = useState(getActivePage());
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    api.get('user/admin')
      .then((ret) => {
        setAdmin(ret)
      })
      .catch((err) => { console.log(err) });

    setActiveTab(getActivePage(window.location.pathname.split('/')[3]))
  }, [window.location.pathname.split('/')[3]])

  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    navigate(`/admin/settings/${tabs[newValue].link}`)
  };

  const TabCustom = (props) => {
    const { title, icon, index, ...others } = props
    return (
      <Tab
        label={
          <Grid container alignItems={'center'} justifyContent={'flex-start'}>
            <Icon style={{ marginRight: 15 }}>{icon}</Icon>{title}
          </Grid>
        }
        {...a11yProps(index)}
        {...others}
      />
    )
  }

  return (
    <Paper style={{ padding: 10 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography
            component="h2"
            variant="h6"
            color="primary"
            style={{ paddingLeft: 5 }}
          >
            Configurações
          </Typography>
        </Grid>
        <Grid item xs={12}><Divider /></Grid>
        <Grid item xs={12} md={'auto'}>
          <Hidden
            mdUp
          >
            <Tabs
              orientation="horizontal"
              variant="scrollable"
              scrollButtons="auto"
              value={activeTab}
              onChange={handleChange}
              aria-label="Vertical tabs example"
            >
              {
                tabs.filter(f => !f.admin || admin).map((m, i) => {
                  return <TabCustom title={m.title} icon={m.icon} index={i} key={i} />
                })
              }
            </Tabs>
          </Hidden>
          <Hidden
            mdDown
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              scrollButtons="auto"
              value={activeTab}
              onChange={handleChange}
              aria-label="Vertical tabs example"
            >
              {
                tabs.filter(f => !f.admin || admin).map((m, i) => {
                  return <TabCustom title={m.title} icon={m.icon} index={i} key={i} />
                })
              }
            </Tabs>
          </Hidden>

        </Grid>
        <Grid item xs>

          {tabs.filter(f => !f.admin || admin).map((m, i) => {
            return <TabPanel value={activeTab} index={i} key={i}>
              {m.child}
            </TabPanel>
          })}

        </Grid>
      </Grid>
    </Paper >
  );
}
