import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as DtPicker } from '@mui/x-date-pickers/DatePicker';
import TextField from './TextField';
import { isErrored } from '../../../utils/form';

export default function DatePicker(props) {

 
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DtPicker
        renderInput={(p) => <TextField {...p} {...props} />}
        inputFormat="DD/MM/YYYY"
        {...props}
      />
    </LocalizationProvider>
  );
}