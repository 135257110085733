import styled from '@emotion/styled';
import { Grid, Icon, IconButton, Paper } from '@mui/material';
import Carousel from 'nuka-carousel/lib/carousel';
import React from 'react';
import withoutPicture from './../../../Images/semFoto.jpg';

export function CardImageSlider(props) {
  const { children, images = [] } = props;

  const Card = styled(Paper)(({ theme }) => ({
    padding: 0,
    overflow: 'hidden',
    transition: 'color ease 0.3s',
    '&:hover': {
      boxShadow: '0 3px 6px 3px lightGray',
    },
    '& .showOnHover': {
      display: 'none',
    },
    '&:hover .showOnHover': {
      display: 'flex',
    }
  }));

  return (
    <Card
      elevation={1}
    >
      <Grid container >

        <Grid item xs={12}>
          <Carousel
            defaultControlsConfig={{ pagingDotsClassName: 'showOnHover' }}
            renderCenterLeftControls={({ previousDisabled, previousSlide }) => (
              <IconButton
                onClick={previousSlide}
                disabled={previousDisabled}
                className='showOnHover'
                size='small'
                style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', marginLeft: 5 }}
              >
                <Icon>chevron_left</Icon>
              </IconButton>
            )}
            renderCenterRightControls={({ nextDisabled, nextSlide }) => (
              <IconButton
                onClick={nextSlide}
                disabled={nextDisabled}
                className='showOnHover'
                style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', marginRight: 5 }}
                size='small'
              >
                <Icon>chevron_right</Icon>
              </IconButton>
            )}

          >
            {
              images && Array.isArray(images) && !images.length &&
              <img
                loading="lazy"
                src={withoutPicture}
                style={{
                  display: 'flex',
                  width: '100%'
                }}
              />
            }
            {
              images && Array.isArray(images) && images.map(pic => {
                return (
                  <img loading="lazy" src={pic} style={{
                    objectFit: 'contain',
                    height: 200,
                    display: 'flex',
                    width: '100%'
                  }} />
                )
              })
            }
          </Carousel>

        </Grid>

        <Grid item xs={12}>
          {children}
        </Grid>

      </Grid>

    </Card>
  )
}