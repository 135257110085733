import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Button, Icon, IconButton } from '@mui/material';
// import Camera from '../Camera/Camera';
import PhotoCam from '../PhotoCam/PhotoCam';

export default function PhotoDialog(props) {
  const [open, setOpen] = useState(false);

  const {
    onGetImage = () => { },
    disabled = false,
    fullScreen = false
  } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getImage = (image) => {
    fetch(image)
    .then((res) => res.arrayBuffer())
    .then((buf) => new File([buf], `camera-${new Date().getTime()}.jpg`, { type: image.split(';')[0].split(':')[1] }))
    .then((img) => {     
      setOpen(false); 
      onGetImage(img);
    })     
  }

  return (
    <>
      {fullScreen &&
        <Button 
          style={{ 
            background: 'none', 
            width: '100%', 
            height: '100%', 
            display:'block' 
          }} 
          onClick={handleClickOpen}
          disabled={disabled}
        >
          <Icon style={{color: 'rgba(0,0,0,0.7)'}}>photo_camera</Icon>
          <p 
            style={{ 
              margin: 0, 
              marginTop: -5, 
              fontWeight: "bold", 
              color: 'rgba(0,0,0,0.7)'
            }}
          >
            Capturar Foto
          </p>
        </Button>
      }
      {!fullScreen &&
        <IconButton
          onClick={handleClickOpen}
          disabled={disabled}
        >
          <Icon>photo_camera</Icon>
        </IconButton>
      }

      <Dialog
        open={open}
        onClose={handleClose}
      >
        <PhotoCam onGetImage={getImage} />
      </Dialog>
    </>
  );
}