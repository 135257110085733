import React, {  useState } from 'react';
import {  Icon,ImageListItem, ImageListItemBar } from '@mui/material';
import Dropzone from 'react-dropzone';
import PhotoDialog from '../../Components/Dialog/PhotoDialog';
import ConfirmDialog from '../../Components/Dialog/ConfirmDialog';

export default function PeopleImageList(props) {
  const [fileName, setFileName] = useState('');

  const {
    accept = ['.jpg', '.jpeg', '.png'],
    pictures = [],
    label = 'Arraste os arquivos aqui',
    iconCheck,
    singleFile,
    deletePic = () => { },
    onChange = () => { }
  } = props;



  return (
    <div
      style={{
        display: "flex", 
        overflow: 'hidden',
        overflowX: 'auto',
        gap: 5
      }}>
      <ImageListItem >
        <div style={{
          marginBottom: 5,
          textAlign: 'center',
          width: 240,
          height: '30%',
          border: "1px dashed lightGray",
          borderRadius: 5,
          cursor: 'pointer',
        }}
        >
          < PhotoDialog fullScreen onGetImage={(img) => { onChange([ img ]) }}/>
        </div>
        <Dropzone onDrop={onChange} >
          {
            ({ getRootProps, getInputProps, isDragActive }) => (
              <section style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                height: '67%'
              }}
                accept={accept}
                multiple={!singleFile}
              >
                <div {...getRootProps()} >

                  <input {...getInputProps()} />

                  <div style={{
                    textAlign: 'center',
                    padding: '11% 8% 5% 8%',
                    width: 240,
                    height: '100%',
                    border: "1px dashed lightGray",
                    borderRadius: 5,
                    cursor: 'pointer'
                  }} >
                    {!isDragActive ? <Icon style={{ color: 'rgba(0,0,0,0.6)' }} fontSize={'large'}>file_upload</Icon> :
                      <Icon style={{ color: 'rgba(0,0,0,0.6)' }} fontSize={'large'}>save_alt</Icon>}
                    <p style={{ margin: 0, color: 'rgba(0,0,0,0.7)' }}><b> {isDragActive ? 'Solte os arquivos nesta área' : label}</b></p>
                    {iconCheck && <Icon style={{ color: 'green', fontSize: 30, }} >check</Icon>}

                    <p
                      style={{
                        color: '#595959',
                        fontSize: '9pt',
                        margin: 0
                      }}
                    >
                      {
                        !singleFile ?
                          '(Ou arraste para o lado para vizualizar os arquivos)' :
                          'Arrasque ou clique para selecionar'
                      }
                    </p>
                    {fileName}
                  </div>
                  <input
                    accept={accept}
                    style={{ display: 'none' }}
                    id="contained-button-file"
                    multiple={!singleFile}
                    type="file"
                    onChange={onChange}
                  />

                </div>
              </section>
            )
          }
        </Dropzone>

      </ImageListItem>
      {
        pictures.map((m) => {
          return (
            <ImageListItem key={{ m }}>
              <img
                style={{ width: 250, borderRadius: 5 }}
                loading="lazy" src={m} />
              <ImageListItemBar
                style={{ padding: 5, borderRadius: '0px 0px 5px 5px' }}
                actionIcon={
                  <ConfirmDialog
                    iconColor="rgba(255,250,250, 0.8)"
                    onDelete={() => { deletePic(m) }} />
                }
              />

            </ImageListItem>
          )
        })
      }
    </div>
  )
}