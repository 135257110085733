import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Icon, IconButton } from '@mui/material';

export default function ConfirmDialog(props) {
  const [open, setOpen] = useState(false);

  const {
    title = 'Confirmação Excluir',
    iconColor = '',
    message = 'Você realmente quer excluir este registro? Ao confirmar o mesmo será apagado.',
    onDelete = () => { },
    buttonTitle,
    disabled
  } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {
        !buttonTitle ?
          <IconButton disabled={disabled} onClick={handleClickOpen}>
            <Icon sx={{ color: iconColor }}>delete</Icon>
          </IconButton> :
          <Button
            disabled={disabled}
            variant="outlined"
            sx={{ textTransform: 'none' }}
            onClick={handleClickOpen}>{buttonTitle}
          </Button>
      }

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>Cancelar</Button>
          <Button onClick={() => { handleClose(); onDelete(); }}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}