import { DatePicker, Space } from 'antd';

const { RangePicker } = DatePicker;

const DataRange = (props) => (
  <Space direction="vertical" style={{width: '100%'}}>
    <RangePicker
      style={{  padding: 9, width: '100%'}}
      {...props}
    />
  </Space>
);

export default DataRange;