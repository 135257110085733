import * as XLSX from 'xlsx';
var FileSaver = require('file-saver');

export const exportPlanning = (data = [], fileName = 'relatorio.xlsx', header = []) => { 

  let headerInt = header.length ? header : Object.getOwnPropertyNames(data[0]);
  const ws = XLSX.utils.book_new();
  XLSX.utils.sheet_add_aoa(ws, [headerInt]);
  XLSX.utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });
  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', cellStyles:true });
  const finalData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
  FileSaver.saveAs(finalData, fileName)
}