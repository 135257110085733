export const validateEmail = (email) => {
  let re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export const isErrored = (requiredField, value, formData = {}) => {
  return requiredField && !value || (requiredField && requiredField.rule && !requiredField.rule(value, formData)) == true
}

export const allowSubmit = (requiredFields = [], formData = {}) => {
  let allow =
    !requiredFields.filter(f =>
      !f.id.includes('.*.')
    ).filter(f =>
      !formData[f.id] || (f.rule && !f.rule(formData[f.id], formData))
    ).length;

  if (allow)
    requiredFields.filter(f => f.id.includes('.*.')).map(m => {
      allow =
        !formData[m.id.split('.')[0]].filter(f => {
          return !f.deleted && (!f[m.id.split('.')[2]] || (m.rule && !m.rule(f[m.id.split('.')[2]], formData)))
        }).length;
    })

  return allow;
}