import { Divider, Grid, Icon } from '@mui/material';
import React, { useEffect, useState } from 'react';
import api from '../../../api';
import { toast } from 'react-toastify';
import Button from '../../Components/Form/Button';
import TextField from '../../Components/Form/TextField';
import { allowSubmit } from '../../../utils/form';

export default function ConfigDetail(props) {

  const { profile = false, onSubmit = () => { } } = props;
  const [config, setConfig] = useState({});
  const [configId, setConfigId] = useState(props.configId);
  const [requiredFields, setRequiredFields] = useState([
    { id: 'type', message: 'O campo nome é obrigatório' },
    { id: 'key', message: 'Por favor preencha um e-mail válido' },
    { id: 'value', message: 'O campo Value é obrigatório' }
  ]);

  const getData = () => {
    api.get(`config/${configId}`)
      .then(setConfig)
      .catch(toast.error);
  }

  useEffect(() => {
    if (configId != 'new')
      getData();
  }, [configId])

  const handleSubmit = (e) => {
    e.preventDefault();

    if (configId != 'new')
      api.put(`config/${configId}`, config)
        .then(() => {
          toast.success('Configuração atualizada com sucesso!');
          onSubmit();
        })
        .catch(toast.error);
    else
      api.post(`config/`, config)
        .then((ret) => {
          toast.success('Configuração cadastrada com sucesso!');
          setConfigId(ret);
          onSubmit();
        })
        .catch(toast.error);
  }

  return (
    <Grid container justifyContent={'center'} style={{ padding: 10 }}>
      <form onSubmit={handleSubmit}>
        <Grid item xs={12} style={{ maxWidth: 900 }}>
          <Grid container >
            <Grid item xs={12}>
              <Grid container columnSpacing={2} >

                <Grid item md={6} xs={12}>

                  <TextField
                    id="type"
                    label="Type"
                    name="type"
                    autoFocus
                    requiredFields={requiredFields}
                    value={config.type || ''}
                    onChange={e => setConfig({ ...config, type: e.target.value })}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    id="key"
                    label="Key"
                    name="key"
                    value={config.key || ''}
                    requiredFields={requiredFields}
                    onChange={e => setConfig({ ...config, key: e.target.value })}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <TextField
                    id="value"
                    label="Value"
                    name="value"
                    value={config.value || ''}
                    requiredFields={requiredFields}
                    inputProps={{ maxLength: 500 }}
                    onChange={e => setConfig({ ...config, value: e.target.value })}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12} style={{ marginTop: 10 }}>
              <Grid container columnSpacing={1}>
                <Grid item>
                  <Button
                    disabled={!allowSubmit(requiredFields, config)}
                    variant='contained'
                    title='Salvar'
                    type='submit'
                    endIcon={
                      <Icon>check</Icon>
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  )
}