import { Grid } from '@mui/material';
import * as React from 'react';
import constructionImage from './../../../Images/404.gif';



export default function InConstruction(props) {


  return (
    <Grid container justifyContent={'center'}>
      <Grid item>
        <img src={constructionImage} style={{ width: '100%' }} />
      </Grid>
    </Grid>

  );
}