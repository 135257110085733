import { Chip, Divider, FormLabel, Grid, Icon, Switch, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Buton from '../../Components/Form/Button';
import ConfirmDialog from '../../Components/Dialog/ConfirmDialog';
import GateDetail from './GateDetail';
import RowCollapse from '../../Components/Collapse/RowCollapse';


export default function GatePage(props) {

  const { unityData = { gates: [] }, setUnityData = () => { }, requiredFields = [] } = props;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 10 }}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs>
            <Typography component="h2" variant="h6" color="primary">
              Cadastro de Catracas
            </Typography>
          </Grid>
          <Grid item>
            <Buton
              onClick={() => {
                setUnityData({ ...unityData, gates: [{ id: 'new', key: Math.random(), created_at: new Date() }, ...unityData.gates] })
              }}
              title="Adicionar"
              endIcon={
                <Icon>
                  add
                </Icon>
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
        <Divider />
      </Grid>
      {
        unityData && unityData.gates && !unityData.gates.length &&
        <Grid item xs={12} >
          <Grid container justifyContent={'center'}>
            Sem registros para mostrar
          </Grid>

          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        </Grid>
      }

      {
        unityData.gates.filter(f => !f.deleted).map((m) => {
          return (
            <Grid item xs={12} key={m.key}>
              <RowCollapse
                collapsed={(
                  <GateDetail
                    gate={m}
                    unityData={unityData}
                    setUnityData={setUnityData}
                    requiredFields={requiredFields}
                  />
                )}
                open={m.id == 'new'}
              >

                <Grid container style={{ alignItems: 'center' }}>
                  <Grid item xs>
                    <p style={{ margin: 0, fontWeight: 500 }}>{m.name}</p>
                    <p style={{ margin: 0, fontSize: 14 }}>{m.ip_address}</p>
                  </Grid>

                  <Grid item>
                    <Chip icon={<Icon style={{ fontSize: 15, paddingLeft: 2 }} >{m.direction == 'in' ? 'arrow_upward' : 'arrow_downward'} </Icon>} label={m.direction == 'in' ? 'Entrada' : 'Saída'} variant="outlined" color={m.direction == 'in' ? 'success' : 'error'} />
                  </Grid>

                  <Grid item>
                    <Switch
                      {...FormLabel}
                      defaultChecked />
                  </Grid>

                  <Grid item>
                    <ConfirmDialog onDelete={() => { m.deleted = true; setUnityData({ ...unityData }) }} />
                  </Grid>

                </Grid>

              </RowCollapse>

              <Grid item xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
                <Divider />
              </Grid>

            </Grid>
          )
        })
      }
    </Grid>
  )
}
