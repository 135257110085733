import { Divider, Grid, Icon, IconButton, Typography } from '@mui/material';
import React from 'react';
import Buton from '../../Components/Form/Button';
import ConfirmDialog from '../../Components/Dialog/ConfirmDialog';
import RowCollapse from '../../Components/Collapse/RowCollapse';
import PeopleDocumentDetail from './PeopleDocumentDetail';

export default function PeopleDocumentPage(props) {

  const { peopleData = { documents: [] }, setPeopleData = () => { }, requiredFields = [] } = props;

  return (
    <Grid container>
      <Grid item xs>
        <Typography component="h2" variant="h6" color="primary">
          Documentos
        </Typography>

      </Grid>
      <Grid item>
        <Buton
          onClick={() => { setPeopleData({ ...peopleData, documents: [{ id: 'new', key: Math.random(), created_at: new Date() }, ...peopleData.documents] }) }}
          disabled={peopleData.external_id}
          title="Adicionar"
          endIcon={
            <Icon>
              add
            </Icon>
          }
        />
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
        <Divider />
      </Grid>
      {
        peopleData && peopleData.documents && !peopleData.documents.length &&
        <Grid item xs={12} >
          <Grid container justifyContent={'center'}>
            Sem registros para mostrar
          </Grid>

          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        </Grid>
      }

      {
        peopleData.documents.filter(f => !f.deleted).map((m) => {
          return (
            <Grid item xs={12} key={m.key}>
              <RowCollapse
                collapsed={(
                  <PeopleDocumentDetail
                    document={m}
                    peopleData={peopleData}
                    setPeopleData={setPeopleData}
                    requiredFields={requiredFields}
                  />
                )}
                open={m.id == 'new'}
              >
                <Grid container style={{ alignItems: 'center' }}>
                  <Grid item xs>
                    <p style={{ margin: 0, fontWeight: 500 }}>{m.document}</p>
                    <p style={{ margin: 0, fontSize: 14 }}>{m.type}</p>

                  </Grid>

                  <Grid item>
                    <ConfirmDialog onDelete={() => { m.deleted = true; setPeopleData({ ...peopleData }) }} disabled={peopleData.external_id}/>
                  </Grid>
                </Grid>
              </RowCollapse>
              <Grid item xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
                <Divider />
              </Grid>
            </Grid>
          )
        })
      }
    </Grid>
  )
}
