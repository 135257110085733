import * as React from 'react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar, Fab, Hidden, Icon, Slide, Typography } from '@mui/material';
import logo from './../../../Images/laisLogo.png';
import AdminMenu from './AdminMenu';
import AccountMenu from './UserMenu';
import Copyright from './Copyright';
import { ScrollTop } from './ScrollTop';

const drawerWidth = 250;

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  background: 'rgba(255,255,255,0.8)',
  position: 'fixed',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  })
}));

const MiniDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.down('sm')]: {
          width: 0
        }
      }),
    },
  }),
);


export default function AdminInterface(props) {
  const { children } = props;
  const [open, setOpen] = React.useState(localStorage.getItem('drawerOpen') == 'true' ? true : false);
  const [potableOpen, setPortableOpen] = React.useState(false);
  const toggleDrawer = (toggle = undefined) => {
    let isOpen = toggle != undefined ? toggle : !open;
    setOpen(isOpen);
    localStorage.setItem('drawerOpen', `${isOpen}`);
  };

  const togglePortable = (toggle = undefined) => {
    setPortableOpen(toggle != undefined ? toggle : !potableOpen);
  };

  const [userMenu, setUserMenu] = React.useState(null);
  const [pageTitle, setPageTitle] = React.useState('');

  const ref = React.useRef();

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        sx={{ boxShadow: 0 }}
      >
        <Toolbar style={{ paddingLeft: 20, paddingRight: 10 }} variant="dense">
          <IconButton
            edge="start"
            aria-label="open drawer"
            onClick={() => { toggleDrawer(); togglePortable() }}
          >
            <MenuIcon />
          </IconButton>
          <img style={{ height: 35, marginLeft: 10, marginRight: 20 }} src={logo} alt={'logo'} onClick={()=>{window.location = '/admin'}}/>

          <Typography
            component="h1"
            variant="h5"
            color="GrayText"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {pageTitle}
          </Typography>

          <IconButton
            style={{ marginLeft: 10 }}
            onClick={e => setUserMenu({ ...e })}
          >
            <Avatar
              alt={JSON.parse(localStorage.getItem('user')).name}
              src={JSON.parse(localStorage.getItem('user')).picture}
              sx={{ width: 40, height: 40, border: '1px solid #d1d1d1' }}
            />
          </IconButton>
          <AccountMenu event={userMenu} />
        </Toolbar>
      </AppBar>

      <Hidden smDown>
        <MiniDrawer
          variant="permanent"
          PaperProps={{ style: { border: 'none' } }}
          style={{ marginTop: 49, background: 'rgba(255,255,255,0.9)' }}
          open={open}
        >
          <AdminMenu open={open} setSelected={setPageTitle} />
        </MiniDrawer>
      </Hidden>

      <Hidden smUp>
        <MuiDrawer
          variant='temporary'
          open={potableOpen}
          onClose={() => { setPortableOpen(false) }}
        >

          <Toolbar style={{ paddingLeft: 20, paddingRight: 10, width: drawerWidth + 50 }} variant="dense">
            <IconButton
              edge="start"
              aria-label="close drawer"
              onClick={() => togglePortable()}
            >
              <Icon>close</Icon>
            </IconButton>
          </Toolbar>
          <AdminMenu open={open} setSelected={setPageTitle} toggleDrawer={togglePortable} />
        </MuiDrawer>
      </Hidden>

      <div
        ref={ref}
        style={{
          flexGrow: 1,
          overflow: 'auto',
          height: '100vh',
          padding: 15,
          backgroundColor: 'rgb(240,240,240)'

        }}
      >
        <div style={{height: 55}}/>
        {children}
        <Copyright />
      </div>

      <ScrollTop {...props} reference={ref} />

    </Box>
  );
}