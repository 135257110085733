import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import api from '../../../api';
import { toast } from 'react-toastify';
import { Pagination, Typography } from '@mui/material';
import PeopleCard from './PeopleCard';

export default function PeopleListCard(props) {

  const { noPic, filter, setFilter = () => { } } = props;
  const [peopleData, setPeopleData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState({ page: 1, total: 1});

  const getData = (offset = 0, limit = 48) => {

    api.get('people', {
      params: {
        noPic: `${noPic == true}`,
        offset,
        limit,
        ...filter
      }
    })
      .then(data => {
        setPeopleData(data.list);
        setTotal(Number(data.total));
        setPage({ total: Math.ceil(Number(data.total) / limit), offset, limit, page: (offset / limit) + 1 })
      })
      .catch(err => {
        toast.error(err)
      })
  };

  const handlePage = (e, value) => {
    getData((value - 1) * page.limit, page.limit);
  }

  useEffect(() => { 
    getData() 
  }, [filter]);

  return peopleData.length > 0 && (
    <Grid container spacing={2} >
      <Grid item xs={12}>
        <Typography component="h2" variant="h6" color="primary">{`${total} Pessoas ${noPic ? 'sem foto' : 'com foto'}`}</Typography>
      </Grid>
      {peopleData.map((m, i) =>
        <PeopleCard people={m} filter={filter} setFilter={setFilter} index={i} />
      )}

      <Grid item xs={12} container justifyContent={'center'} style={{ marginTop: 10 }}>
        <Pagination count={page.total} page={page.page} onChange={handlePage} />
      </Grid>
    </Grid>
  )
}