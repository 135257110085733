import React, { useEffect } from 'react';
import GoogleLogin from '@leecheuk/react-google-login';
import Button from '../Form/Button';
import { gapi } from 'gapi-script'
import { toast } from 'react-toastify';

function Login(props) {
  const { onLogin = () => { } } = props;
  const clientId = "133485094951-h7uf847fn68cj779edj0dvcqlaeugt67.apps.googleusercontent.com";
  useEffect(() => {
    gapi.load("client:auth2", () => {
      gapi.auth2.init({ clientId: clientId })
    })
  }, [])

  const onSignIn = (response) => {
    onLogin(response)
  }

  const error = (response) => {
    toast.error('Tela login google encerrada');
  }

  return (
    <GoogleLogin
      clientId={clientId}
      buttonText="Login"
      onSuccess={onSignIn}
      onFailure={error}
      isSignedIn={true}
      render={renderProps => (
        <Button
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
          style={{ width: '100%', height: 43, border: '1px solid #e3e3e4' }}
          buttonText='Login com conta Google'
          title={
            <>
              <img
                style={{ height: 28 }}
                src={'https://imagepng.org/wp-content/uploads/2019/08/google-icon-2.png'}
                alt={'Conta Google'}
              />
              <p style={{ paddingLeft: 10, fontSize: 13, color: '#3F3F3F', textTransform: 'capitalize' }}>Login conta Google</p>
            </>
          }
        />
      )}
    />
  );
}

export default Login
