import React, { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Copyright from './../Components/UI/Copyright';
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import SHA256 from 'crypto-js/sha256';
import { CircularProgress, Grid, Icon, IconButton } from '@mui/material';
import { toast } from 'react-toastify';
import TextField from '../Components/Form/TextField';
import Button from '../Components/Form/Button';

export default function Login() {

  let navigate = useNavigate();
  const [mail, setMail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    api.post('auth/requestreset', { mail })
      .then(ret => {
        toast.success('E-mail enviado com sucesso!')
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        toast.error('E-mail precisa ser preenchido');
      })
  };


  return (

    <Container component="main" maxWidth="xs" >
      <CssBaseline />
      <Box
        sx={{
          marginTop: 15,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >

        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate sx={{ mt: 1 }}
        >

          <Grid
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              background: '#f9f9f9',
              padding: 50,
              paddingBottom: 85,
              borderRadius: 20,
              boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
            }}>

            <img
              style={{ height: 85, marginBottom: 5, transform: 'rotate(-80deg)' }}
              src={'https://cdn-icons-png.flaticon.com/512/4192/4192711.png'}
              alt={'logo'}
            />

            <p style={{
              color: '#202020',
              fontSize: 24,
              fontWeight: '500',
              marginBottom: 10,
              marginTop: 0
            }}>Esqueceu a senha?</p>

            <TextField
              autoFocus
              margin="normal"
              required
              fullWidth
              id="email"
              label="Digite seu e-mail"
              name="email"
              autoComplete="email"
              value={mail || ''}
              onChange={e => setMail(e.target.value)}
            />

            <Button
              type="submit"
              fullWidth
              size='default'
              variant="contained"
              disabled={loading}
              sx={{ mt: 1, mb: 2 }}
              title={<>Recuperar Senha
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
              </>
              }
            />
            <IconButton
              onClick={() => { navigate(-1) }}
              style={{
                borderRadius: 10,
                paddingTop: 0,
                paddingBottom: 0,
                paddingRight: 15,
                paddingLeft: 10,
                transition: '1s'
              }}>

              <Icon>arrow_back</Icon>
              <p style={{ fontSize: 15, fontWeight: 'bold', marginLeft: 7 }}>Retornar ao Login</p>
            </IconButton>
            <Copyright />
          </Grid>
        </Box>
      </Box>

    </Container>
  );
}
