import { Divider, Grid, Icon, Typography } from '@mui/material';
import React from 'react';
import ButonDefault from '../../Components/Form/Button';
import ConfirmDialog from '../../Components/Dialog/ConfirmDialog';
import RowCollapse from '../../Components/Collapse/RowCollapse';
import PeopleContactDetail from './PeopleContactDetail';
import { maskPhone } from '../../../utils/masks';

export default function PeopleContactPage(props) {

  const { peopleData = { contacts: [] }, setPeopleData = () => { }, requiredFields = [] } = props;

  return (
    <Grid container>

      <Grid item xs >
        <Typography component="h2" variant="h6" color="primary">
          Contatos
        </Typography>
      </Grid>
      <Grid item>
        <ButonDefault
          onClick={() => {
            setPeopleData(
              {
                ...peopleData, contacts:
                  [{ id: 'new', key: Math.random(), created_at: new Date() },
                  ...peopleData.contacts]
              })
          }}
          title="Adicionar"
          disabled={peopleData.external_id}
          endIcon={
            <Icon>
              add
            </Icon>
          }
        />

      </Grid>
      <Grid item xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
        <Divider />
      </Grid>
      {
        peopleData && peopleData.contacts && !peopleData.contacts.length &&
        <Grid item xs={12} >
          <Grid container justifyContent={'center'}>
            Sem registros para mostrar
          </Grid>

          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        </Grid>
      }

      {
        peopleData.contacts.filter(f => !f.deleted).map((m) => {
          return (
            <Grid item xs={12} key={m.key}>
              <RowCollapse
                collapsed={(
                  <PeopleContactDetail
                    contact={m}
                    peopleData={peopleData}
                    setPeopleData={setPeopleData}
                    requiredFields={requiredFields}
                  />
                )}
                open={m.id == 'new'}
              >

                <Grid container style={{ alignItems: 'center' }}>
                  <Grid item xs>
                    <p style={{ margin: 2, fontSize: 15, fontWeight: 450 }}> {m.name} </p>
                    <p style={{ margin: 2, fontSize: 16 }}> {m.mail} </p>
                    <p style={{ margin: 2, fontSize: 15 }}> {maskPhone(m.phone || '')} </p>
                  </Grid>

                  <Grid item>
                    <ConfirmDialog onDelete={() => { m.deleted = true; setPeopleData({ ...peopleData }) }} disabled={peopleData.external_id}/>
                  </Grid>
                </Grid>
              </RowCollapse>
              <Grid item xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
                <Divider />
              </Grid>
            </Grid>
          )
        })
      }
    </Grid>
  )
}
