import { Grid, Switch } from '@mui/material';
import React from 'react';
import { maskPhone } from '../../../utils/masks';
import TextField from '../../Components/Form/TextField';

export default function PeopleContactDetail(props) {
  const { peopleData = { documents: [] }, setPeopleData = () => { }, contact = {}, requiredFields = [] } = props;

  const setContact = () => {
    contact.updated = true;
    setPeopleData({ ...peopleData })
  }

  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12} sm={12} md={'auto'}>
        <TextField
          id="contacts.*.name"
          label="Nome"
          name="name"
          autoComplete="name"
          variant="standard"
          autoFocus
          disabled={peopleData.external_id}
          requiredFields={requiredFields}
          value={contact.name || ''}
          onChange={e => { contact.name = e.target.value; setContact() }}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={'auto'} >
        <TextField
          id="contacts.*.phone"
          label="Telefone"
          name="phone"
          autoComplete="phone"
          variant="standard"
          disabled={peopleData.external_id}
          requiredFields={requiredFields}
          value={maskPhone(contact.phone) || ''}
          onChange={e => {
            contact.phone = maskPhone(e.target.value);
            setContact()
          }}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={'auto'}>
        <TextField
          id="contacts.*.mail"
          label="E-mail"
          name="mail"
          variant="standard"
          disabled={peopleData.external_id}
          value={contact.mail || ''}
          requiredFields={requiredFields}
          onChange={e => { contact.mail = e.target.value; setContact() }}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={'auto'} >
        <p style={{ fontSize: 12, marginBottom: -6, paddingLeft: 10, color: 'gray' }}>
          Notificar:
        </p>
        <Switch
          id="notify"
          checked={contact.notify || false}
          onChange={e => {
            contact.notify = !contact.notify; setContact()
          }}
        />
      </Grid>
    </Grid>
  )
}