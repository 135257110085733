import React, { useEffect, useState } from 'react';
import ButtonDefault from '../../Components/Form/Button';
import { CircularProgress, FormControlLabel, Grid, Icon, Radio, RadioGroup, Typography } from "@mui/material";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import DataRange from '../../Components/DataRange/DataRange';
import { Input } from 'antd';
import { toast } from 'react-toastify';
import api from '../../../api';
import useDebounce from '../../../utils/useDebounce';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { exportPlanning } from '../../Components/ExportExcel/ExportExcel';
import dayjs from 'dayjs';

export default function AccessFilter(props) {
  const { onFilter = () => { }, accessData = [] } = props;
  // const [filter, setFilter] = useState({ notified: 'both' });
  const [filter, setFilter] = useState({ 
    notified: 'both',  
    dateStart: new Date(`${new Date().toDateString()} 00:00:00`),
    dateEnd: new Date(`${new Date().toDateString()} 23:59:59`),
    // ...JSON.parse(sessionStorage.getItem('pageFilter')) 
  });


  const [isLoading, setIsLoading] = useState(false);


  const debounceSearch = useDebounce(filter, 500);
  useEffect(() => {
    onFilter(filter)
    // sessionStorage.setItem('pageFilter', JSON.stringify(filter));
  }, [debounceSearch])

  const handleSubmit = (e) => {
    e.preventDefault();
    onFilter(filter);
  }

  const [reportData, setReportData] = useState();

  useEffect(() => {
    if (reportData) {

      if (reportData.offset >= reportData.total) {
        let date = new Date();
        exportPlanning(
          reportData.list,
          `relatorio-${date.getDay()}-${date.getMonth()}-${date.getFullYear()}.xlsx`,
          ['Nome', 'Cliente', 'Catraca', 'Data', 'Grupo de Unidade', 'Notificado', 'Data da Notificação'])
        setIsLoading(false)
      }
      else {
        api.get('access', {
          params: {
            offset: reportData.offset + reportData.limit,
            limit: reportData.limit,
            ...filter
          }
        })
          .then((ret) => { 
            ret.list.map(m => {
              m.date = new Date(m.date).toLocaleString('pt-BR');
              m.notified = m.notified ? 'Sim' : 'Não';
              m.notified_date = m.notified_date ? new Date(m.notified_date).toLocaleString('pt-BR') : null;
            })

            setReportData({ ...reportData, ...ret, list: [...reportData.list, ...ret.list] }) 
          })
          .catch((err) => {
            toast.error(err);
            setIsLoading(false);
          })
      }
    }

  }, [reportData])



  const clearFilter = () => {
    setFilter({ notified: 'both' });
  }

  const downloadPlanning = () => {
    setIsLoading(true);
    api.get('access', {
      params: {
        offset: 0, limit: 300, ...filter
      }
    })
      .then(ret => {

        ret.list.map(m => {
          m.date = new Date(m.date).toLocaleString('pt-BR');
          m.notified = m.notified ? 'Sim' : 'Não';
          m.notified_date = m.notified_date ? new Date(m.notified_date).toLocaleString('pt-BR') : null;
        })

        setReportData(ret)
      })
      .catch((err) => {
        toast.error(err);
        setIsLoading(false);
      })
  }


  return (

    <form onSubmit={handleSubmit} >

      <Grid container alignItems={'flex-end'} style={{ padding: 20 }}>

        <Grid item xs={12}>
          <Typography component="h4" variant="h10" color="primary">
            Filtros
          </Typography>
        </Grid>

        <Grid container alignItems={'center'} spacing={2} >

          <Grid item xs={12} md={'auto'} xl={2} alignItems={'center'} >
            <Input
              style={{ height: 40 }}
              placeholder='Nome'
              id="search"
              name="search"
              autoComplete="search"
              value={filter.name || ''}
              onChange={e => { setFilter({ ...filter, name: e.target.value }) }}
            />
          </Grid>

          <Grid item xs={12} md={'auto'} xl={2}>
            <Input
              style={{ height: 40 }}
              id="gate"
              placeholder='Catraca'
              name="gate"
              autoComplete="gate"
              value={filter.gate || ''}
              onChange={e => { setFilter({ ...filter, gate: e.target.value }) }}
            />
          </Grid>

          <Grid item xs={12} md={'auto'} xl={2}>
            <Input
              style={{ padding: 8 }}
              id="group"
              placeholder='Série'
              name="group"
              autoComplete="group"
              value={filter.group || ''}
              onChange={e => { setFilter({ ...filter, group: e.target.value }) }}
            />
          </Grid>

          <Grid item xs={12} md={4} xl={3}>
            <DataRange
              id="date"
              label="Período"
              name="date"
              defaultValue={[dayjs(filter.dateStart), dayjs(filter.dateEnd)]}
              onChange={(date, dateString) => {
                setFilter({
                  ...filter, 
                  dateStart: dateString[0] ? new Date(`${dateString[0]} 00:00:00`) : null,
                  dateEnd: dateString[1] ? new Date(`${dateString[1]} 23:59:59`) : null
                })
              }}
            />
          </Grid>

          <Grid item xs={12} md={'auto'} xl={'auto'}>
            <RadioGroup
              aria-labelledby="demo-error-radios"
              name="quiz"
              value={filter.notified}
              row
              onChange={e => {
                setFilter({ ...filter, notified: e.target.value })
              }}
            >
              <FormControlLabel value="true" control={<Radio />} label="Notificados" />
              <FormControlLabel value="false" control={<Radio />} label="Não" />
              <FormControlLabel value="both" control={<Radio />} label="Ambos" />
            </RadioGroup>
          </Grid>

          <Grid item >
            <ButtonDefault
              type='submit'
              variant="contained"
              title="Filtrar"
              endIcon={<Icon>search</Icon>}
            />
          </Grid>
          <Grid item >
            <ButtonDefault
              type='submit'
              variant="contained"
              title="Limpar"
              endIcon={<Icon>clear</Icon>}
              onClick={clearFilter}
            />
          </Grid>
          <Grid item >
            <ButtonDefault
              type='submit'
              variant="contained"
              title="Exportar"
              disabled={!accessData.length}
              endIcon={isLoading ?
                <CircularProgress
                  size={24}
                /> : <FileDownloadIcon />}
              onClick={downloadPlanning}
            />
          </Grid>
        </Grid>

      </Grid>
    </form>
  )
}
