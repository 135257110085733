import React from "react";
import Login from "../Pages/Login";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import defaultTheme from "../../style/defaultTheme";
import { ThemeProvider } from '@mui/material/styles';
import UnityPage from '../Pages/Unity/UnityPage';
import Dashboard from "../Pages/Dashboard/DashboardPage";
import PeoplePage from '../Pages/People/PeoplePage';
import AccessPage from '../Pages/Access/AccessPage';
import SettingsPage from '../Pages/Settings/SettingsPage';
import ResetPassword from '../Pages/ResetPassword';
import ConfirmPassword from '../Pages/ConfirmPassword';
import AdminInterface from '../Components/UI/AdminInterface';
import InConstruction from '../Components/UI/InConstruction';
import { BrowserRouter, Navigate, useRoutes } from "react-router-dom";

function Logout() {
  localStorage.removeItem('user');
  return (<Navigate to="/login" />)
}

function PrivateRoute({ children }) {
  return localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).auth ? <AdminInterface>{children}</AdminInterface> : <Navigate to="/logout" />
}

function Routes() {
  return useRoutes([
    { path: '/', element: <Login /> },
    { path: '/login', element: <Login /> },
    { path: '/logout', element: <Logout /> },
    { path: '/resetpassword', element: <ResetPassword /> },
    { path: '/confirmpassword', element: <ConfirmPassword /> },
    { path: '/admin', element: <PrivateRoute><Dashboard /></PrivateRoute> },
    { path: '/admin/people/*', element: <PrivateRoute><PeoplePage /></PrivateRoute> },
    { path: '/admin/settings/*', element: <PrivateRoute><SettingsPage /></PrivateRoute> },
    { path: '/admin/dashboard', element: <PrivateRoute><Dashboard /></PrivateRoute> },
    { path: '/admin/unity/*', element: <PrivateRoute><UnityPage /></PrivateRoute> },
    { path: '/admin/access/*', element: <PrivateRoute><AccessPage /></PrivateRoute> },
    { path: '/admin/*', element: <PrivateRoute><InConstruction /></PrivateRoute> },
    { path: '*', element: <InConstruction /> },
  ])
}

export default function AppRoutes() {

  return (
    <ThemeProvider theme={defaultTheme}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />

    </ThemeProvider>
  );
};