import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker as DtPicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from './TextField';

export default function DateTimePicker(props) {

 
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DtPicker
        renderInput={(p) => <TextField {...p} {...props} />}
        inputFormat="DD/MM/YYYY hh:mm:ss a"
        {...props}
      />
    </LocalizationProvider>
  );
}