import { Link, Typography } from '@mui/material';

export default function Copyright(props) {
  return (
    <Typography 
      variant="body2" 
      color="text.secondary" 
      align="center" {...props}
      style={{
        paddingTop: 20
      }}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://tarponprata.com.br/">
        Tarpon Prata.
      </Link>{' - Todos os direitos reservados '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  
  );
}
