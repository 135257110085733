import api from '../../../api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import FaceIcon from '@mui/icons-material/Face';
import React, { useEffect, useState } from 'react';
import { Button, Chip, Grid, Paper } from '@mui/material';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import FaceRetouchingOffIcon from '@mui/icons-material/FaceRetouchingOff';

export default function DashboardCounters() {
  const [counterData, setCounterData] = useState({ serviceStatus: [] });

  let navigate = useNavigate();

  const getData = () => {
    api.get('dashboard')
      .then(data => {
        setCounterData(data);
      })
      .catch(toast.error);
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <Grid container spacing={3} justifyContent='center'>
      <Grid item xs={12} sm={12} md={6} lg={4.5} xl={4.5} >
        <Paper sx={{
          p: 2, display: 'flex', flexDirection: 'column', height: 200, borderBottom: '4px solid #2e7d32', borderRadius: 3, '&:hover': {
            transition: '500ms',
            height: 200,
            boxShadow: '0 3px 6px 3px lightGray',
          }
        }}>
          {/* <Chip style={{ width: 250, margin: '0 auto', fontSize: 12 }} variant="outlined" color="success" icon={<DoNotDisturbAltIcon />} label="Status Serviços" /> */}
          <Grid container style={{paddingTop: 25  }}>
                <Grid item xs><b>Serviço</b></Grid>
                <Grid item xs={2}><b>Ativo</b></Grid>
                <Grid item xs={4}><b>Ultima execução</b></Grid>
                {/* <Grid item xs={3}>Serviço: {m.name}</Grid> */}
              </Grid>
          {
            counterData.serviceStatus.map(m => {
              return <Grid container>
                <Grid item xs>{m.module} - {m.name}</Grid>
                <Grid item xs={2}>{m.active ? 'Sim' : 'Não'}</Grid>
                <Grid item xs={4}>{new Date(m.last_execute).toLocaleString('pt-BR')}</Grid>
                {/* <Grid item xs={3}>Serviço: {m.name}</Grid> */}
              </Grid>
            })
          }
          {/* <h1 style={{ marginBottom: 80, margin: 'auto', fontSize: 35, marginBottom: 45 }}>{counterData.lastAccess}</h1> */}
        </Paper>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={2.5} xl={2.5} >
        <Paper sx={{
          p: 2, display: 'flex', flexDirection: 'column', height: 200, borderBottom: '4px solid #2e7d32', borderRadius: 3, '&:hover': {
            transition: '500ms',
            height: 200,
            boxShadow: '0 3px 6px 3px lightGray',
          }
        }}>
          <Chip style={{ width: 250, margin: '0 auto', fontSize: 12 }} variant="outlined" color="success" icon={<FaceIcon />} label="Pessoas Cadastradas Com Foto" />
          <h1 style={{ marginBottom: 80, margin: 'auto', fontSize: 35 }}>{counterData.pic}</h1>
          <Button onClick={() => navigate('people')} style={{ width: 210, margin: '0 auto', marginTop: -20 }} variant="text">Veja mais </Button>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={2.5} xl={2.5} >
        <Paper sx={{
          p: 2, display: 'flex', flexDirection: 'column', height: 200, borderBottom: '4px solid #2e7d32', borderRadius: 3, '&:hover': {
            transition: '500ms',
            height: 200,
            boxShadow: '0 3px 6px 3px lightGray',
          }
        }}>
          <Chip style={{ width: 250, margin: '0 auto', fontSize: 12 }} variant="outlined" icon={<FaceRetouchingOffIcon />} label="Pessoas Cadastradas Sem Foto" />
          <h1 style={{ marginBottom: 80, margin: 'auto', fontSize: 35 }}>{counterData.nopic}</h1>
          <Button onClick={() => navigate('people')} style={{ width: 210, margin: '0 auto', marginTop: -20 }} variant="text">Veja mais</Button>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={2.5} xl={2.5} >
        <Paper sx={{
          p: 2, display: 'flex', flexDirection: 'column', height: 200, borderBottom: '4px solid #2e7d32', borderRadius: 3, '&:hover': {
            transition: '500ms',
            height: 200,
            boxShadow: '0 3px 6px 3px lightGray',
          }
        }}>
          <Chip style={{ width: 250, margin: '0 auto', fontSize: 12 }} variant="outlined" color="success" icon={<FaceIcon />} label="Acessaram à escola" />
          <h1 style={{ marginBottom: 80, margin: 'auto', fontSize: 35, color: '#212121', marginBottom: 50 }}>{counterData.peoplein}</h1>
        </Paper>
      </Grid>

      {/* <Grid item xs={12} md={'auto'} lg={'auto'} xl={2.4}>
        <Paper sx={{
          p: 2, display: 'flex', flexDirection: 'column', height: 200, borderBottom: '4px solid #d32f2f', borderRadius: 3, '&:hover': {
            transition: '500ms',
            height: 200,
            boxShadow: '0 3px 6px 3px lightGray',
          }
        }}>
          <Chip style={{ width: 250, margin: '0 auto', fontSize: 12 }} variant="outlined" color="error" icon={<DoNotDisturbAltIcon />} label="Saíram da Escola" />
          <h1 style={{ marginBottom: 80, margin: 'auto', fontSize: 35, color: '#212121', marginBottom: 50 }}>50</h1>
        </Paper>
      </Grid> */}
    </Grid>
  )
}
