import { Divider, FormControlLabel, Grid, Icon, IconButton, InputAdornment, Paper, Switch, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import api from '../../../api';
import { toast } from 'react-toastify';
import GatePage from '../Gate/GatePage';
import { useNavigate } from 'react-router-dom';
import { maskCep, maskPhone } from '../../../utils/masks';
import Button from '../../Components/Form/Button';
import { ufList } from '../../../utils/lists';
import TextField from '../../Components/Form/TextField';
import Select from '../../Components/Form/Select';
import { allowSubmit } from '../../../utils/form';

export default function UnityDetail(props) {
  const { unityId } = props;

  const [unityData, setUnityData] = useState({ gates: [] });
  const [showToken, setShowToken] = useState(false);
  const navigate = useNavigate();

  const getData = () => {
    if (unityId != 'new') {
      api.get(`unity/${unityId}`)
        .then(ret => {
          setUnityData({ ...ret, zip: maskCep(ret.zip) });
        })
        .catch(err => {
          toast.error(err)
        })
    }
  }

  useEffect(() => {
    getData()
  }, [unityId])


  const handleSubmit = (e) => {
    e.preventDefault();

    if (unityId == 'new') {
      api.post('unity', unityData)
        .then(ret => {
          toast.success('Unidade inserida com sucesso!');
          navigate(`/admin/unity/${ret}`)
        })
        .catch(toast.error)
    } else {
      api.put('unity', unityData)
        .then(ret => {
          toast.success('Unidade atualizada com sucesso!');
          getData();
        })
        .catch(toast.error)
    }
  }

  const findCEP = (cep) => {
    if (cep)
      api.get(`cep/${cep}`)
        .then(ret => {
          setUnityData({
            ...unityData,
            address: ret.logradouro,
            district: ret.bairro,
            city: ret.localidade,
            state: ret.uf
          })
        })
        .catch(message => {
          toast.error(message = 'CEP inválido')
        })
  }

  const requiredFields = [
    { id: 'zip', message: 'O campo CEP é obrigatório' },
    { id: 'gates.*.direction', message: 'Obrigatório' },
    { id: 'address', message: 'Campo endereço Obrigatório' },
    { id: 'name', message: 'O campo nome é obrigatório' },
    { id: 'number', message: 'O campo número é obrigatório' },
    { id: 'gates.*.name', message: 'O campo Nome é obrigatório' },
    { id: 'gates.*.ip_address', message: 'O campo IP é obrigatório' },
  ]

  const textFieldRef = React.useRef(null);

  return (
    <Paper style={{ padding: 10 }}>
      <Grid container alignItems={'center'} columnSpacing={1} >
        <Grid item xs={'auto'}>
          <IconButton onClick={() => { navigate('/admin/unity/') }}>
            <Icon>arrow_back</Icon>
          </IconButton>
        </Grid>
        <Grid item xs>

          <Typography component="h2" variant="h6" color="primary">
            Detalhe de Unidades
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>

          <Grid container justifyContent={'center'} style={{ padding: 10 }}>
            <form onSubmit={handleSubmit}>
              <Grid item xs={12} style={{ maxWidth: 900 }}>

                <Grid container>
                  <Grid item xs={12}>
                    <Grid container columnSpacing={2}>
                      <Grid item xs md={2}>
                        <TextField
                          style={{ minWidht: 100 }}
                          id="integration_id"
                          label="Id externo"
                          name="integration_id"
                          autoComplete="integration_id"
                          autoFocus
                          requiredFields={requiredFields}
                          value={unityData.integration_id || ''}
                          onChange={e => setUnityData({ ...unityData, integration_id: e.target.value })}
                        />
                      </Grid>
                      <Grid item xs={12} md={8} lg={5}>
                        <TextField
                          id="name"
                          label="Nome"
                          name="name"
                          autoComplete="name"
                          autoFocus
                          requiredFields={requiredFields}
                          value={unityData.name || ''}
                          onChange={e => setUnityData({ ...unityData, name: e.target.value })}
                        />
                      </Grid>
                      <Grid item xs={12} md={4} lg={5}>
                        <TextField
                          id="phone"
                          label="Telefone"
                          name="phone"
                          autoComplete="phone"
                          requiredFields={requiredFields}
                          value={maskPhone(unityData.phone) || ''}
                          onChange={e => setUnityData({ ...unityData, phone: maskPhone(e.target.value) })}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container columnSpacing={2}>
                      <Grid item xs={12} md>
                        <TextField
                          id="zip"
                          label="CEP"
                          name="zip"
                          autoComplete="zip"
                          requiredFields={requiredFields}
                          value={unityData.zip && maskCep(unityData.zip, false) || ''}
                          onBlur={e => findCEP(e.target.value)}
                          onChange={e => setUnityData({ ...unityData, zip: e.target.value })}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">
                              <IconButton
                                onClick={() => { findCEP(unityData.zip) }}
                                edge="end"
                              >
                                <Icon>search</Icon>
                              </IconButton>
                            </InputAdornment>
                          }}
                          style={{ padding: 0 }}
                        />
                      </Grid>

                      <Grid item xs={12} md={7}>
                        <TextField
                          InputProps={{ color: 'info' }}
                          id="address"
                          label="Endereço"
                          name="address"
                          requiredFields={requiredFields}
                          autoComplete="adrress"
                          value={unityData.address || ''}
                          onChange={e => setUnityData({ ...unityData, address: (e.target.value) })}
                        />
                      </Grid>

                      <Grid item xs={12} md>
                        <TextField
                          type="number"
                          id="number"
                          label="Número"
                          name="number"
                          requiredFields={requiredFields}
                          value={unityData.number || ''}
                          onChange={e => setUnityData({ ...unityData, number: e.target.value })}
                        />
                      </Grid>

                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container columnSpacing={2}>
                      <Grid item xs={12} md>
                        <TextField
                          id="complement"
                          label="Complemento"
                          name="complement"
                          value={unityData.complement || ''}
                          onChange={e => setUnityData({ ...unityData, complement: e.target.value })}
                        />
                      </Grid>

                      <Grid item xs={12} md>
                        <TextField
                          id="district"
                          label="Bairro"
                          name="district"
                          value={unityData.district || ''}
                          onChange={e => setUnityData({ ...unityData, district: e.target.value })}
                        />
                      </Grid>

                      <Grid item xs={12} md>
                        <TextField
                          id="city"
                          label="Cidade"
                          name="city"
                          autoComplete="city"
                          value={unityData.city || ''}
                          onChange={e => setUnityData({ ...unityData, city: e.target.value })}
                        />
                      </Grid>

                      <Grid item xs={12} md={-3}>
                        <Select
                          id="state"
                          name="state"
                          label="UF"
                          value={unityData.state || ''}
                          values={ufList.map(m => { return { label: m, value: m } })}
                          onChange={e => setUnityData({ ...unityData, state: e.target.value })}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container style={{ alignItems: 'center' }} columnSpacing={2}>
                      <Grid item style={{ marginLeft: -8 }}>
                        <Switch
                          id="active"
                          name="active"
                          checked={unityData.active || false}
                          onChange={e => setUnityData({ ...unityData, active: e.target.checked })}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} >
                        <p style={{ margin: 0, fontWeight: 'bold', fontSize: 12 }}>Token Catracas:</p>
                        <p
                          id="token"
                          style={{ fontSize: 12, margin: 0, wordBreak: 'break-all', width: '100%' }}
                        >
                          {showToken ? unityData.token : `${unityData.token ? unityData.token.substring(0, 30) : ''}...`}
                          <IconButton size='small' onClick={() => { setShowToken(!showToken) }}><Icon style={{ fontSize: 14 }}>{!showToken ? 'visibility' : 'visibility_off'}</Icon></IconButton>
                          <IconButton size='small' onClick={() => { navigator.clipboard.writeText(unityData.token); toast.info('Token copiado!') }}><Icon style={{ fontSize: 14 }}>content_copy</Icon></IconButton>
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <GatePage
                      unityData={
                        unityData
                      }
                      setUnityData={
                        setUnityData
                      }
                      requiredFields={
                        requiredFields
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container justifyContent={'end'} columnSpacing={1}>
                      <Grid item>
                        <Button
                          size='medium'
                          variant="outlined"
                          title="Cancelar"
                          onClick={() => { navigate(-1) }}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          size='medium'
                          variant="contained"
                          title="Salvar"
                          type="submit"
                          disabled={!allowSubmit(requiredFields, unityData)}
                          endIcon={
                            <Icon>check</Icon>
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

