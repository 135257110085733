import { Avatar, Divider, Grid, Icon, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';
import api from '../../../api';
import { toast } from 'react-toastify';
import Button from '../../Components/Form/Button';
import DateTimePicker from '../../Components/Form/DateTimePicker';
import TextField from '../../Components/Form/TextField';
import { allowSubmit, validateEmail } from '../../../utils/form';
import { SHA256 } from 'crypto-js';
import UserChangePass from './UserChangePass';

export default function UserDetail(props) {

  const { profile = false, onSubmit = () => { } } = props;

  const [userData, setUserData] = useState({});
  const [userId, setUserId] = useState(props.userId);
  const [requiredFields, setRequiredFields] = useState([
    { id: 'name', message: 'O campo nome é obrigatório' },
    { id: 'mail', message: 'Por favor preencha um e-mail válido', rule: validateEmail }
  ]);

  const getData = () => {
    api.get(`user/${userId}`)
      .then(setUserData)
      .catch(toast.error);
  }


  useEffect(() => {
    if (userId != 'new')
      getData();
    else
      setRequiredFields([
        ...requiredFields,
        { id: 'password', message: 'Por favor preencha a senha' },
        {
          id: 'rePassword', message: 'As senhas estão diferentes', rule: (value, formData) => {
            return formData.password == formData.rePassword
          }
        }
      ])
  }, [userId])

  const handleSubmit = (e) => {
    e.preventDefault();

    const postData = { ...userData, password: (SHA256(userData.password)).toString() };
    delete postData.rePassword;

    if (userId != 'new')
      api.put(`user/${userId}`, postData)
        .then(() => {
          toast.success('Usuário atualizado com sucesso!');
          onSubmit();
        })
        .catch(toast.error);
    else
      api.post(`user/`, postData)
        .then((ret) => {
          toast.success('Usuário cadastrado com sucesso!');
          setUserId(ret);
          onSubmit();
        })
        .catch(toast.error);
  }

  const resetPassword = (passData) => {
    let body = {
      password: (SHA256(passData.password)).toString(),
      userId
    };

    if (passData.oldPassword)
      body.oldPassword = (SHA256(passData.oldPassword)).toString();


    api.post(`user/resetpassword`, body)
      .then(() => {
        toast.success('Senha Atualizada!');
      })
      .catch(toast.error);
  }


  const handleProfileImage = e => {
    let form = new FormData();
    form.append("image", e.target.files[0], e.target.files[0].name);

    api.post(
      `/user/userpic/${userId}`,
      form,
      {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
    )
      .then(() => {
        toast.success('Foto atualizada com sucesso')
      })
      .catch(toast.error)
  }

  return (
    <Grid container justifyContent={'center'} style={{ padding: 10 }}>
      <form onSubmit={handleSubmit}>
        <Grid item xs={12} style={{ maxWidth: 900 }}>
          <Grid container >
            {
              profile &&
              <>
                <Grid item xs={12}>
                  <Grid container justifyContent={'center'} >
                    <Avatar src={userData.picture} sx={{ width: 150, height: 150, border: '1px solid #d1d1d1', objectFit: 'scale-down' }} />
                  </Grid>

                  <Grid item >
                    <Grid container justifyContent='center'>
                      <input
                        accept="image/*"
                        id="icon-button-file"
                        type="file"
                        style={{ display: 'none'}}
                        onChange={(e) => {
                          handleProfileImage(e)
                        }}
                      />
                      <label htmlFor="icon-button-file">
                        <Button
                          component='span'
                          style={{ marginTop: 15, padding: 5, fontSize: 12, borderRadius: 3, textTransform: 'none' }}
                          variant='contained'
                          title='Substituir Imagem '
                        />
                      </label>
                      <label htmlFor="icon-button-file">

                      </label>
                      <input
                        accept="image/*"
                        id="icon-button-file"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={(e) => {
                          handleProfileImage(e)
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: 10 }}>
                      <Divider />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            }


            <Grid item xs={12}>
              <Grid container columnSpacing={2} >

                <Grid item md={6} xs={12}>

                  <TextField
                    id="name"
                    label="Nome"
                    name="name"
                    autoFocus
                    requiredFields={requiredFields}
                    value={userData.name || ''}
                    onChange={e => setUserData({ ...userData, name: e.target.value })}
                  />
                </Grid>


                <Grid item md={6} xs={12}>
                  <TextField
                    id="mail"
                    label="E-mail"
                    name="mail"
                    value={userData.mail || ''}
                    requiredFields={requiredFields}
                    onChange={e => setUserData({ ...userData, mail: e.target.value })}
                  />
                </Grid>
              </Grid>
            </Grid>

            {userId == 'new' &&
              <Grid item xs={12}>
                <Grid container columnSpacing={2} >

                  <Grid item md={6} xs={12}>

                    <TextField
                      id="password"
                      label="Senha"
                      name="password"
                      type="password"
                      requiredFields={requiredFields}
                      value={userData.password || ''}
                      onChange={e => setUserData({ ...userData, password: e.target.value })}
                    />
                  </Grid>


                  <Grid item md={6} xs={12}>
                    <TextField
                      id="rePassword"
                      label="Confirmação Senha"
                      name="rePassword"
                      type="password"
                      value={userData.rePassword || ''}
                      requiredFields={requiredFields}
                      formData={userData}
                      onChange={e => setUserData({ ...userData, rePassword: e.target.value })}
                    />
                  </Grid>
                </Grid>
              </Grid>
            }
            {userId != 'new' &&
              <Grid item xs={12}>
                <Grid container columnSpacing={2}>

                  <Grid item md={6} xs={12}>
                    <DateTimePicker
                      disabled
                      id="createdAt"
                      label="Criado em"
                      name="createdAt"
                      value={userData.created_at || ''}
                      onChange={e => setUserData({ ...userData, created_at: e && e.toDate() })}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      disabled
                      id="created_user_name"
                      label="Criado Por"
                      name="created_user_name"
                      autoFocus
                      value={userData.created_user_name || ''}
                      onChange={e => setUserData({ ...userData, created_user_name: e.target.value })}
                    />
                  </Grid>
                </Grid>
              </Grid>
            }

            <Grid item xs={12}>
              <Grid container spacing={2} alignItems={'center'}>
                <Grid item>
                  Ativo:
                  <Switch
                    checked={userData.active}
                    disabled={profile}
                    onChange={e => setUserData({ ...userData, active: e.target.checked })}
                  />
                </Grid>

                <Grid item>
                  Administrador:
                  <Switch
                    checked={userData.admin}
                    disabled={profile}
                    onChange={e => setUserData({ ...userData, admin: e.target.checked })}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2} style={{ marginTop: 0 }}>
                <Grid item xs={12} sm={'auto'}>

                  <Button
                    disabled={!allowSubmit(requiredFields, userData)}
                    variant='contained'
                    title='Salvar'
                    type='submit'
                    endIcon={
                      <Icon>check</Icon>
                    }
                  // style={{ marginTop: 5 }}
                  />
                </Grid>

                <Grid item xs={12} sm={'auto'}>
                  {userId != 'new' &&
                    <UserChangePass profile={profile} onSubmit={resetPassword} />
                  }
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  )
}