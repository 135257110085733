import React, { useState, useEffect } from 'react';
import UnityDetail from './UnityDetail';
import { UnityList } from './UnityList';

export default function Returniunity(props) {
  const [unityId, setUnityId] = useState();

  useEffect(() => {
    setUnityId(window.location.pathname.split('/')[3]);
  }, [window.location.pathname])

  return (
    <>
      {unityId ?
        (<UnityDetail unityId={unityId} />) :
        (<UnityList />)
      }
    </>
  )
}