import api from '../../../api';
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { Divider, Grid, LinearProgress, Pagination, Typography } from "@mui/material";
import LoginFilter from './LogsFilter'


export default function LogsPage() {
  const [logsData, setLogsData] = useState([]);
  const [page, setPage] = useState({ page: 1, total: 1 });
  const [isLoading, setIsLoading] = useState(false);

  const getData = (offset = 0, limit = 100, filter = {}) => {
    setIsLoading(true);

    api.get('logs', {
      params: {
        offset, limit, ...filter
        // ssearch: filter
      }
    })
      .then((data) => {
        setLogsData(data.list)

        setPage({ total: Math.ceil(data.total / limit), offset, limit, page: (offset / limit) + 1 })
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setIsLoading(false);
      })
  }

  useEffect(() => {
    getData();
  }, [])

  const handlePage = (e, value) => {
    getData((value - 1) * page.limit, page.limit);
  }

  return (
    <Grid>
      {isLoading && <LinearProgress />}


      <Grid container style={{  paddingTop: 0 }} alignItems={'flex-end'}>

        <Grid item xs={12}>
          <Typography component="h2" variant="h6" color="primary" style={{ marginLeft: 20 }}>
            Logs do Sistema
          </Typography>
        </Grid>

        <Grid>
          <LoginFilter onFilter={(filter) => { getData('0', '50', filter) }}  />
        </Grid>

        <Grid item xs={12} style={{ paddingTop: 10 }}>
          <Divider />
        </Grid>

        {
          logsData.map((m, i) => {
            return (
              <Grid item xs={12} style={{ paddingTop: 5 }} key={i}>
                <Grid container>

                  <Grid container style={{ alignItems: 'center', paddingLeft: 18 }}>
                    <Grid item md={4} xs={12}>
                      <p style={{ fontSize: 14, margin: 0, fontWeight: 'bold', color: '#343434' }}>Serviço:</p>{m.source}
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <p style={{ fontSize: 14, margin: 0, fontWeight: 'bold', color: '#343434' }}>Mensagem:</p>{m.message}
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <p style={{ fontSize: 14, margin: 0, fontWeight: 'bold', color: '#343434' }}>Data:</p>{ !m.date ? '' : new Date (m.date).toLocaleString()}
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item md={12}>
                      <Divider style={{ paddingTop: 5 }}/>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
            )
          }
          )}
        <Grid item xs={12}>
          <Grid container justifyContent={'center'} style={{ marginTop: 10 }}>
            <Pagination count={page.total} page={page.page} onChange={handlePage} />
          </Grid>
        </Grid>

      </Grid>
    </Grid>
  )
}