import React, { useState, useRef, useEffect } from "react";
import { Camera } from "react-camera-pro";
import { Button, Grid } from "@mui/material";

export default function PhotoCam(props) {
  const { onGetImage = () => { }, disabled = false } = props;

  const camera = useRef(null);
  const [numberOfCameras, setNumberOfCameras] = useState(0);
  const [image, setImage] = useState();



  useEffect(() => {
    if (numberOfCameras == 1)
      camera.current.switchCamera()
  }, [numberOfCameras]);

  return (
    <Grid container style={{
      position: 'relative',
      width: 400,
      // height: 400
    }}>
      {
        image &&
        <Grid item xs={12}>
          <img src={image} style={{
            position: 'relative',
            width: 400,
            height: 400
          }} alt='Image preview' />
        </Grid>
      }
      {
        !image &&
        <Grid item xs={12}>
          <div
            style={{
              position: 'relative',
              width: 400,
              height: 400
            }}
          >
            <Camera
              ref={camera}
              facingMode='user'
              aspectRatio='cover'
              numberOfCamerasCallback={i => setNumberOfCameras(i)}
              errorMessages={{
                noCameraAccessible: 'No camera device accessible. Please connect your camera or try a different browser.',
                permissionDenied: 'Permission denied. Please refresh and give camera permission.',
                switchCamera:
                  'It is not possible to switch camera to different one because there is only one video device accessible.',
                canvas: 'Canvas is not supported.',
              }}
            />
          </div>
        </Grid>
      }

      {!image &&
        <Grid item xs={12}>
          <Button
            onClick={() => {
              const photo = camera.current.takePhoto();
              setImage(photo);
            }}
          >
            Tirar Foto
          </Button>
          {numberOfCameras > 1 &&
            <Button
              hidden={numberOfCameras <= 1}
              onClick={() => {
                camera.current.switchCamera();
              }}
            >
              Trocar Câmera
            </Button>
          }
        </Grid>
      }


      {image &&
        <Grid item xs={12}>
          <Button
            onClick={() => {
              onGetImage(image)
            }}
          >
            Confirmar
          </Button>
          <Button
            hidden={numberOfCameras <= 1}
            onClick={() => {
              setImage();
            }}
          >
            Descartar
          </Button>
        </Grid>
      }

    </Grid>

  )
}