import api from '../../../api';
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { Divider, Grid, LinearProgress, Pagination, Paper, Typography } from "@mui/material";
import AccessFilter from './AccessFilter';


export default function AccessPage() {
  const [accessData, setAccessData] = useState([]);
  const [page, setPage] = useState({ page: 1, total: 1, totalItems: 0 });
  const [isLoading, setIsLoading] = useState(false);

  const getData = (offset = 0, limit = 50, filter = {}) => {
    setIsLoading(true);

    api.get('access', {
      params: {
        offset, limit, ...filter
      }
    })
      .then((data) => {
        setAccessData(data.list)
        setPage({ totalItems: data.total, total: Math.ceil(data.total / limit), offset, limit, page: (offset / limit) + 1 })
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setIsLoading(false);
      })
  }


  const handlePage = (e, value) => {
    const pageFilter = sessionStorage.getItem('pageFilter');
    getData((value - 1) * page.limit, page.limit, JSON.parse(pageFilter));
  }


  return (
    <Paper style={{ overflow: 'hidden' }}>
      {isLoading && <LinearProgress />}

      <AccessFilter onFilter={(filter) => { getData('0', '50', filter) }} accessData={accessData} />

      <Grid container style={{ padding: 20, paddingTop: 0 }} alignItems={'flex-end'}>

        <Grid item xs={12}>
          <Typography component="h2" variant="h6" color="primary">
            Listagem de Acessos ({page.totalItems})
          </Typography>
          <Grid item xs={12} style={{ paddingTop: 10 }}>
            <Divider />
          </Grid>
        </Grid>

        {!accessData.length &&
          <Grid item xs={12}>
            <Grid container justifyContent={'center'}>
              <p>Sem acessos para mostrar</p>
            </Grid>
          </Grid>
        }
        {
          accessData.map((m, i) => {
            return (
              <Grid item xs={12} justifyContent={'space-between'} alignItems={'center'} spacing={2} style={{ paddingTop: 8, paddingBottom: 8 }}>
                <Grid container>

                  <Grid container style={{ alignItems: 'center', paddingLeft: 18 }}>
                    <Grid item md={2.5} xs={12}>
                      <p style={{ fontSize: 13, margin: 0, fontWeight: 'bold', color: '#343434' }}>Nome:</p>{m.namepeople}
                    </Grid>

                    <Grid item md={'2'} xs={12}>
                      <p style={{ fontSize: 13, margin: 0, fontWeight: 'bold', color: '#343434' }}>Gate:</p>{m.namegate}
                    </Grid>

                    <Grid item md={2} xs={12}>
                      <p style={{ fontSize: 13, margin: 0, fontWeight: 'bold', color: '#343434' }}>Data:</p>{new Date(m.date).toLocaleString('pt-BR')}
                    </Grid>

                    <Grid item md={1.5} xs={12}>
                      <p style={{ fontSize: 13, margin: 0, fontWeight: 'bold', color: '#343434' }}>Grupo:</p>{m.nameunitygrop}
                    </Grid>

                    <Grid item md={1.5} xs={12}>
                      <p style={{ fontSize: 13, margin: 0, fontWeight: 'bold', color: '#343434' }}>Notificado:</p>{m.notified ? 'Sim' : 'Não'}
                    </Grid>

                    <Grid item md={1.5} xs={12}>
                      <p style={{ fontSize: 13, margin: 0, fontWeight: 'bold', color: '#343434' }}>Data de Notificação:</p>{m.notified_date ? new Date(m.notified_date).toLocaleString('pt-BR') : ''}
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: 10 }}>
                      <Divider />
                    </Grid>

                  </Grid>

                </Grid>
              </Grid>
            )
          }
          )}

        <Grid item xs={12}>
          <Grid container justifyContent={'center'} style={{ marginTop: 10 }}>
            <Pagination count={page.total} page={page.page} onChange={handlePage} />
          </Grid>
        </Grid>

      </Grid>
    </Paper >
  )
}