import { Divider, Grid, Icon, IconButton, InputAdornment, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TextField from '../../Components/Form/TextField';
import PeopleListCard from './PeopleListCard';
import { useNavigate, useParams } from 'react-router-dom';
import useDebounce from '../../../utils/useDebounce';
import Select from '../../Components/Form/Select';
import Button from '../../Components/Form/Button';


export default function PeopleList(props) {
  const { setPeopleId = () => { } } = props;
  const [searched, setSearched] = useState({ active: true, syncStatus: 'Todos', ...JSON.parse(sessionStorage.getItem('peopleFilter')) });
  const [filter, setFilter] = useState({ active: true, syncStatus: 'Todos', ...JSON.parse(sessionStorage.getItem('peopleFilter')) });
  const debounceSearch = useDebounce(filter, 500);
  useEffect(() => {
    setSearched(filter)
  }, [debounceSearch])

  const navigate = useNavigate();

  useEffect(() => {
    sessionStorage.setItem('peopleFilter', JSON.stringify(searched))
  }, [searched])

  return (
    <Grid container >
      <Grid item xs={12}>
        <Grid container alignItems={'center'} justifyContent={'flex-end'}>
          <Grid item xs={12} >
            <form onSubmit={(e) => { e.preventDefault(); setSearched(filter) }}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Grid container alignItems={'flex-end'} justifyContent={'flex-end'} spacing={2}>
                <Grid item sm={12} md>
                </Grid>

                <Grid item xs={12} sm={'auto'} >
                  <Select
                    sx={{ margin: 0 }}
                    allowNull={false}
                    id="syncStatus"
                    name="syncStatus"
                    label="Status Catracas"
                    variant="standard"
                    value={filter.syncStatus || ''}
                    values={[{ label: 'Todos', value: 'Todos' }, { label: 'Sincronizado', value: 'Sincronizado' }, { label: 'Não Sincronizado', value: 'Não Sincronizado' }, { label: 'Erro', value: 'Erro' }]}
                    onChange={e => { setFilter({ ...filter, syncStatus: e.target.value }) }}
                  />
                </Grid>

                <Grid item xs={12} sm={'auto'} >
                  <Select
                    sx={{ margin: 0 }}
                    allowNull={false}
                    id="active"
                    name="active"
                    label="Status"
                    variant="standard"
                    value={filter.active || ''}
                    values={[{ label: 'Ativo', value: 'true' }, { label: 'Inativo', value: 'false' }, { label: 'Todos', value: 'both' }]}
                    onChange={e => { setFilter({ ...filter, active: e.target.value }) }}
                  />
                </Grid>

                <Grid item xs={12} sm={'auto'}>
                  <TextField
                    style={{ margin: 0 }}
                    id="search"
                    variant="standard"
                    label="Buscar"
                    name="search"
                    autoComplete="search"
                    value={filter.search}
                    onChange={e => setFilter({ ...filter, search: e.target.value })}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">
                        <IconButton
                          type='submit'
                          edge="end"
                        >
                          <Icon>search</Icon>
                        </IconButton>
                      </InputAdornment>
                    }}
                  />

                </Grid>
                <Grid item >
                  <Button
                    onClick={() => { setSearched({ ...filter }) }}
                    variant="outlined"
                    title="Atualizar"
                    endIcon={
                      <Icon>
                        refresh
                      </Icon>
                    }
                  />
                </Grid>
                <Grid item >
                  <Button
                    onClick={() => { navigate(`/admin/people/new`); }}
                    variant="contained"
                    title="Adicionar"
                    endIcon={
                      <Icon>
                        add
                      </Icon>
                    }
                  />
                </Grid>

              </Grid>
            </form>

          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 20 }}>
        <Grid container spacing={2} >

          <Grid item xs={12}>
            <PeopleListCard noPic setPeopleId={setPeopleId} filter={searched} setFilter={setSearched} />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <PeopleListCard setPeopleId={setPeopleId} filter={searched} setFilter={setSearched} />
          </Grid>

        </Grid >
      </Grid>
    </Grid>
  )
}