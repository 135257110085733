
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PeopleDetail from './PeopleDetail';
import PeopleList from './PeopleList';

export default function PeoplePage(props) {
  const [peopleId, setPeopleId] = useState();

  useEffect(() => {
    setPeopleId(window.location.pathname.split('/')[3]);
  }, [window.location.pathname])

  return (
    <Grid container justifyContent={'center'}>
      {peopleId ?
        <Grid item xs={12} >
          <PeopleDetail peopleId={peopleId} />
        </Grid> :
        <Grid item xs={12} >
          <PeopleList setPeopleId={setPeopleId} />
        </Grid>
      }
    </Grid>
  );
}