import api from '../../../api';
import { Grid, Paper } from '@mui/material';
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import graphic from '../../../Images/graphic.png'

export default function DashboardChart(props) {

  const [chartData, setChartData] = useState({ dates: [], groups: [] });

  const getData = () => {
    api.get('dashboard/getcounterbygroup')
      .then(data => {
        setChartData(data);
      })
      .catch(toast.error);
  }

  useEffect(() => {
    getData();
  }, [])

  const randomHexColor = () => {
    let r = Math.floor(Math.random() * (255 + 1));
    let g = Math.floor(Math.random() * (255 + 1));
    let b = Math.floor(Math.random() * (255 + 1));
    let hr = r.toString(16).padStart(2, '0');
    let hg = g.toString(16).padStart(2, '0');
    let hb = b.toString(16).padStart(2, '0');
    return "#" + hr + hg + hb;
  }

  const divStyle = {
    color: 'blue',
  };

  return (
    <Paper {...props} >
      {!chartData.dates.length &&
        <Grid item xs={12} style={{ padding: 20 }} >
          <Grid container alignItems={'center'} justifyContent={'center'} spacing={4}>
            <Grid item style={{ maxWidth: 600 }} >
              <h1 style={{ color: '#1971c9', margin: 0, textAlign: 'center' }}>
                No momento não há informações para serem exibidas no gráfico.
              </h1>
            </Grid>
            <Grid item>
              <img style={{ width: '18em' }} src={graphic} />
            </Grid>
          </Grid>
        </Grid>
      }
      {chartData.dates.length > 0 &&
        <Grid container style={{ height: 300 }}>
          <ResponsiveContainer>

            <BarChart
              data={chartData.dates}
              margin={{
                top: 20,
                right: 30,
                left: 0,
                bottom: 5,
              }}
              style={{zIndex: 3}}

            >

              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="dayaccess"
                style={{
                  fontSize: '12',
                }} />
              <YAxis allowDecimals={false}
                style={{
                  fontSize: '12',
                }} />
              <Tooltip />
              <Legend />

              {
                chartData.groups.map((m, i) => {
                  return (
                    <Bar
                      dataKey={m}
                      style={{ fontSize: 7 }}
                      name={m}
                      fill={randomHexColor()}
                    />
                  )
                })
              }

            </BarChart>
          </ResponsiveContainer>
        </Grid>
      }

    </Paper>
  );
}
