import api from '../../../api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Divider, Grid, Icon, IconButton, Paper, Switch, Typography } from '@mui/material';
import Button from '../../Components/Form/Button';
import { allowSubmit } from '../../../utils/form';
import DatePicker from '../../Components/Form/DatePicker';
import TextField from '../../Components/Form/TextField';
import PeopleDocumentPage from './PeopleDocumentPage';
import PeopleContactPage from './PeopleContactPage';
import PeopleImageList from './PeopleImageList';

export default function PeopleDetail(props) {

  const { peopleId } = props;
  const [peopleData, setPeopleData] = useState({ documents: [], contacts: [], pictures: [], active: true });
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false)

  const getData = () => {
    if (peopleId != 'new') {
      api.get(`people/${peopleId}`)
        .then(ret => {
          setPeopleData(ret);
        })
        .catch(err => {
          toast.error(err)
        })
    }
  }

  useEffect(() => {
    getData()
  }, [peopleId])

  const delUpImage = (id) => {

    if (peopleData.newPictures && peopleData.newPictures.length) {
      let form = new FormData();

      for (let img of peopleData.newPictures) {
        form.append("image", img, img.name);
      }

      api.post(
        `/people/${id}/picture`,
        form,
        {
          headers: {
            'content-type': 'multipart/form-data'
          }
        }
      )
        .then(() => {
          setIsLoading(false);
          if (peopleId == 'new') {
            toast.success('Pessoa inserida com sucesso!');
            navigate(`/admin/people/${id}`)
          }
          else {
            toast.success('Pessoa atualizada com sucesso!');
            getData();
          }

        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err)
          getData();
        })

    }

    if (peopleData.delPictures && peopleData.delPictures.length) {

      api.delete('people/picture', {
        data: {
          pictures: peopleData.delPictures, peopleId
        }
      })
        .then(() => {
          if (peopleId == 'new' && !(peopleData.newPictures || peopleData.newPictures.length)) {
            toast.success('Pessoa inserida com sucesso!');
            navigate(`/admin/people/${id}`)
          }
          else {
            toast.success('Pessoa atualizada com sucesso!');
            getData();
          }
        })
        .catch((err) => {
          toast.error(err)
          getData();
        })
    }

    if ((!peopleData.newPictures || !peopleData.newPictures.length) && (!peopleData.delPictures || !peopleData.delPictures.length)) {
      setIsLoading(false)
      toast.success('Pessoa atualizada com sucesso!')
    }

  }


  const handleSubmit = (e) => {
    e.preventDefault();

    if (!peopleData.documents.length) {
      toast.error('Insira pelo menos 1 documento para salvar.')
    }
    else {
      setIsLoading(true);

      //chamar a api de post pois é um new  
      if (peopleId == 'new') {
        api.post('people', peopleData)
          .then(ret => {
            delUpImage(ret);
          })
          .catch((err) => {
            toast.error(err);
            setIsLoading(false);
          })
      } else {
        api.put('people', peopleData)
          .then(ret => {
            delUpImage(peopleId)
          })
          .catch((err) => {
            toast.error(err);
            setIsLoading(false);
          })
      }
    }
  }

  const requiredFields = [
    { id: 'birth', message: 'O campo é obrigatório' },
    { id: 'name', message: 'O campo nome é obrigatório' },
    { id: 'documents.*.type', message: 'Documento é obrigatório' },
    { id: 'documents.*.document', message: 'Numero do documento obrigatório' },
    { id: 'contacts.*.name', message: 'O campo nome é obrigatório' },
    { id: 'contacts.*.phone', message: 'O campo telefone é obrigatório' },
  ]

  const deletePic = (picture) => {
    setPeopleData({
      ...peopleData,
      pictures: peopleData.pictures.filter(f => f != picture),
      delPictures: [
        ...(peopleData.delPictures || []),
        ...peopleData.pictures.filter(f => f == picture)
      ]
    });
  }

  const addImages = (images) => {
    setPeopleData({
      ...peopleData,
      pictures: [
        ...peopleData.pictures,
        ...images.map(m => URL.createObjectURL(m))
      ],
      newPictures: [
        ...(peopleData.newPictures || []),
        ...images
      ]
    })
  }

  return (
    <Paper>
      <Grid container alignItems={'center'} style={{ padding: 5 }}>

        <Grid item >
          <IconButton onClick={() => { navigate('/admin/people') }}>
            <Icon>arrow_back</Icon>
          </IconButton>
        </Grid>

        <Grid item padding={1}>
          <Typography component="h2" variant="h6" color="primary">
            Detalhe de Pessoas
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent={'center'} style={{ padding: 20 }} >
            <form onSubmit={handleSubmit}>
              <Grid item style={{ maxWidth: 900 }} >

                <Grid container columnSpacing={2}>
                  <Grid item xs={12} md={3}>
                    <TextField
                      id="external_id"
                      label="Id Externo"
                      name="external_id"
                      disabled
                      requiredFields={requiredFields}
                      value={peopleData.external_id || ''}
                      onChange={e => setPeopleData({ ...peopleData, external_id: e.target.value })}
                    />
                  </Grid>

                  <Grid item xs={12} md={9}>
                    <TextField
                      style={{ width: '100%' }}
                      id="name"
                      label="Nome"
                      name="name"
                      autoFocus
                      disabled={peopleData.external_id}
                      requiredFields={requiredFields}
                      value={peopleData.name || ''}
                      onChange={e => {
                        setPeopleData({ ...peopleData, name: e.target.value })
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <DatePicker
                      id="birth"
                      label="Data de Nascimento"
                      name="birth"
                      disabled={peopleData.external_id}
                      requiredFields={requiredFields}
                      value={peopleData.birth || ''}
                      onChange={e => setPeopleData({ ...peopleData, birth: e && e.toDate() })}
                    />
                  </Grid>

                  <Grid item xs={12} md={3} style={{ marginTop: 15 }}>
                    Ativo: <Switch
                      id="active"
                      name="active"
                      checked={peopleData.active || false}
                      onChange={e => setPeopleData({ ...peopleData, active: e.target.checked })}
                      inputProps={{ 'aria-label': 'controlled' }}

                    />
                  </Grid>

                  <Grid item xs={12}>
                    <PeopleDocumentPage
                      peopleData={
                        peopleData
                      }
                      setPeopleData={
                        setPeopleData
                      }
                      requiredFields={
                        requiredFields
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <PeopleContactPage peopleData={peopleData} setPeopleData={setPeopleData} requiredFields={requiredFields} />
                  </Grid>

                  <Grid item xs={12} style={{ width: '85vw' }} >
                    <PeopleImageList pictures={peopleData.pictures} onChange={addImages} deletePic={deletePic} />
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container justifyContent={'end'} spacing={1} marginTop={1} >

                      <Grid item>
                        <Button
                          size='medium'
                          variant="outlined"
                          title="Cancelar"
                          onClick={() => { navigate(-1) }}
                        />
                      </Grid>

                      <Grid item>
                        <Button
                          size='medium'
                          variant="contained"
                          title="Salvar"
                          type="submit"
                          disabled={!allowSubmit(requiredFields, peopleData) || isLoading}
                          endIcon={!isLoading ? <Icon>check</Icon> : <CircularProgress
                            size={24}
                            sx={{
                              color: 'primary',
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginTop: '-12px',
                              marginLeft: '-12px',
                            }}
                          />}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid >
      </Grid >
    </Paper >
  )
}