import Grid from '@mui/material/Grid';
import AccessPage from '../Access/AccessPage';
import DashboardChart from './DashboardChart';
import React from 'react';
import DashboardCounters from './DashboardCounters';


export default function Dashboard() {

  return (

    <Grid container spacing={3}>

      <Grid item xs={12}>
        <DashboardCounters />
      </Grid>

      <Grid item xs={12}>
        <DashboardChart />
      </Grid>

      <Grid item xs={12}>
        < AccessPage />
      </Grid>

    </Grid>
  );
}