import AppRoutes from "./Containers/Routes/Routes";
import { ConfigProvider } from 'antd';
import ptBR from 'antd/locale/pt_BR';

function App() {
  return (
    <ConfigProvider locale={ptBR}>
      <AppRoutes />
    </ConfigProvider>
  );
}

export default App;
