import { Button as MUIButton} from "@mui/material";

export default function Button(props) {
  let {title, ...others} = props;
  return (
    <MUIButton
      size='small'
      fullWidth
      style={{ textTransform: 'none' }}
      {...others}
    >
      {title}
    </MUIButton>
  )
}