import { Grid } from '@mui/material';
import React from 'react';
import Select from '../../Components/Form/Select';
import TextField from '../../Components/Form/TextField';

export default function PeopleDocumentDetail(props) {
  const { peopleData = { documents: [] }, setPeopleData = () => { }, document = {}, requiredFields = [] } = props;

  const setDocument = () => {
    document.updated = true;
    setPeopleData({ ...peopleData })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={'auto'}>
        <Select
          id="documents.*.type"
          name="type"
          label="Documento"
          autoFocus
          variant="standard"
          value={document.type || ''}
          disabled={peopleData.external_id}
          requiredFields={requiredFields}
          values={[{ label: 'CPF', value: 'CPF' }, { label: 'RA', value: 'RA' }, { label: 'RG', value: 'RG' }]}
          onChange={e => { document.type = e.target.value; setDocument() }}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={'auto'}>
        <TextField
          id="documents.*.document"
          label="N° Documento"
          name="document"
          autoComplete="document"
          variant="standard"
          disabled={peopleData.external_id}
          requiredFields={requiredFields}
          value={document.document || ''}
          onChange={e => { document.document = e.target.value; setDocument() }}
        />
      </Grid>
    </Grid>
  )
}
