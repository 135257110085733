import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { FormHelperText, Select as MUISelect } from '@mui/material';
import { isErrored } from '../../../utils/form';

export default function Select(props) {
  // values = [{value: 1, label: 'Diego'}]

  const { value, id, allowNull = true, requiredFields = [] } = props;

  const requiredField = requiredFields.find(f => f.id == id);

  const error = isErrored(requiredField, value);

  const errorProps = {
    error,
    required: requiredField != undefined,
    helperText: error ? requiredField.message : ''
  };



  return (
    <FormControl
      fullWidth
      {...props}
      {...errorProps}
      style={{ marginTop: props.variant == 'standard' ? 5 : 16, minWidth: 150 }}
    >
      <InputLabel
        {...props}
        {...errorProps}
        style={{ marginTop: props.variant == 'standard' ? props.value ? 11 : 9 : props.value ? 0 : -7 }}
      >
        {props.label}
      </InputLabel>
      <MUISelect
        sx={{ height: 40 }}
        {...props}
        {...errorProps}
        style={{ paddingTop: props.variant == 'standard' ? 8 : 2 }}
      >
        {allowNull && <MenuItem value={null}>Nenhum</MenuItem>}
        {
          props.values.map((m,i) => {
            return (
              <MenuItem value={m.value} key={i}>{m.label}</MenuItem>
            )
          })
        }
      </MUISelect>
      {error && <FormHelperText>{requiredField.message}</FormHelperText>}
    </FormControl>
  );
}