import { Avatar, Chip, Divider, Grid, Icon, IconButton, InputAdornment, Pagination, Paper, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';
import api from '../../../api';
import { toast } from 'react-toastify';
import Button from '../../Components/Form/Button';
import ConfirmDialog from '../../Components/Dialog/ConfirmDialog';
import RowCollapse from '../../Components/Collapse/RowCollapse';
import UserDetail from './UserDetail';
import TextField from '../../Components/Form/TextField';
import useDebounce from '../../../utils/useDebounce';

export default function UserPage() {

  const [userData, setUserData] = useState([]);
  const [page, setPage] = useState({ page: 1, total: 1 });
  const [filter, setFilter] = useState({});

  const debounceSearch = useDebounce(filter, 500);
  useEffect(() => {
    getData()
  }, [debounceSearch])

  const getData = (offset = 0, limit = 50) => {
    api.get('user', {
      params: {
        offset, limit, ...filter
      }
    })
      .then(ret => {
        setUserData(ret.list);

        setPage({ total: Math.ceil(ret.total / limit), offset, limit, page: (offset / limit) + 1 })

        // setLoading(false);
      })
      .catch(err => {
        // setLoading(false);
        toast.error(err)

      })

  }


  useEffect(() => {
    getData()
  }, [])

  const handlePage = (e, value) => {
    getData((value - 1) * page.limit, page.limit);
  }

  const deleteUser = (user) => {
    api.delete(`user/${user.id}`)
      .then(() => {
        getData(page.offset, page.limit);
        toast.success('Usuário deletado com sucesso!');
      })
      .catch(err => {
        toast.error(err);
      })

  }

  return (
    <Grid container style={{ padding: 10 }}>
      <Grid item xs={12} style={{ marginBottom: 10 }}>
        <Grid container justifyContent={'flex-end'} spacing={1} alignItems={'flex-end'} >
          <Grid item xs md={1.8} style={{ marginRight: 10 }}>
            <form onSubmit={(e) => { e.preventDefault(); getData() }}>
              <TextField
                id="search"
                variant="standard"
                autoFocus
                label="Buscar"
                name="search"
                autoComplete="search"
                value={filter.search}
                onChange={e => { setFilter({ ...filter, search: e.target.value }) }}

                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton
                      type='submit'
                      edge="end"
                    >
                      <Icon>search</Icon>
                    </IconButton>
                  </InputAdornment>
                }}
                style={{ margin: 0 }}
              />
            </form>

          </Grid>

          <Grid item >
            <Button
              // onClick={() => { navigate(`/admin/unity/new`); }}
              variant="contained"
              title="Adicionar"
              endIcon={
                <Icon>
                  add
                </Icon>
              }
              onClick={() => { setUserData([{ id: `new-${Math.random()}`, created_at: new Date(), active: false }, ...userData]) }}
            />
          </Grid>

        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>
      {
        userData.map((m) => {
          return (
            <Grid item xs={12} key={m.id}>
              {/* <Paper> */}
              <Grid container>

                <Grid item xs={12}>
                  <RowCollapse
                    collapsed={(
                      <UserDetail
                        userId={String(m.id).split('-')[0]}
                        onSubmit={() => {
                          getData();
                        }}
                      />
                    )}
                    open={String(m.id).split('-')[0] == 'new'}
                  >
                    <Grid
                      container
                      alignItems={'center'}
                      spacing={1}
                      style={{ paddingTop: 8, paddingBottom: 8 }}
                    >
                      <Grid item xs>
                        <Grid
                          container
                          alignItems={'center'}
                          spacing={1}
                        >
                          <Grid item xs={12} md={'auto'} >
                            <Avatar
                              alt={m.picture}
                              src={m.picture}
                            />
                          </Grid>


                          <Grid item xs={12} md>
                            <p style={{ margin: 0 }}><b>{m.name}</b></p>
                            <p style={{ margin: 0 }}>{m.mail}</p>
                          </Grid>

                          <Grid item xs={12} md={'auto'}>
                            {m.admin && <Chip icon={<Icon>admin_panel_settings</Icon>} label="Admin" variant="outlined" />}
                          </Grid>

                          <Grid item xs={12} md={'auto'}>
                            <p style={{ margin: 0, fontSize: 10 }}><b>Criado em:</b></p>
                            {new Date(m.created_at).toLocaleString()}
                          </Grid>
                          <Grid item xs={12} md={'auto'}>
                            <p style={{ margin: 0, fontSize: 10 }}><b>Status:</b></p>
                            <Switch checked={m.active} disabled />
                          </Grid>

                        </Grid>
                      </Grid>


                      <Grid item>
                        <ConfirmDialog onDelete={() => deleteUser(m)} />
                      </Grid>


                    </Grid>

                  </RowCollapse>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            </Grid>
          )
        })
      }


      <Grid item xs={12}>
        <Grid container justifyContent={'center'} style={{ marginTop: 10 }}>
          <Pagination count={page.total} page={page.page} onChange={handlePage} />
        </Grid>
      </Grid>
    </Grid>
  )
}