import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Icon, Portal } from '@mui/material';
import Button from '../../Components/Form/Button';
import TextField from '../../Components/Form/TextField';

export default function UserChangePass(props) {
  const [open, setOpen] = useState(false);
  const [passData, setPassData] = useState({});

  const { onSubmit = () => { }, profile = false } = props;

  const handleClickOpen = () => {
    setOpen(true);
    setPassData({});
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [requiredFields, setRequiredFields] = useState([
    { id: 'password', message: 'Por favor preencha a nova senha'},
    {
      id: 'rePassword', message: 'As senhas estão diferentes', rule: (value, formData) => {
        return formData.password == formData.rePassword
      }
    }
  ]);

  useEffect(()=>{
    if (profile) setRequiredFields([
      ...requiredFields, 
      { id: 'oldPassword', message: 'Por favor preencha a senha atual' },
    ])
  }, [profile])

  return (
    <>

      <Button
        variant='outlined'
        title='Alterar Senha'
        onClick={handleClickOpen}
        endIcon={
          <Icon>key</Icon>
        }
      // style={{ marginLeft: 10 }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">
          Alterar Senha
        </DialogTitle>
        <DialogContent>
          <Grid container maxWidth={300} >

            {profile && <Grid item xs={12}>
              <TextField
                autoFocus
                id="oldPassword"
                label="Senha"
                name="oldPassword"
                type="password"
                requiredFields={requiredFields}
                value={passData.oldPassword || ''}
                onChange={e => setPassData({ ...passData, oldPassword: e.target.value })}
              />
            </Grid>}

            <Grid item xs={12}>
              <TextField
                id="password"
                label="Senha"
                autoFocus={!profile}
                name="password"
                type="password"
                requiredFields={requiredFields}
                value={passData.password || ''}
                onChange={e => setPassData({ ...passData, password: e.target.value })}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="rePassword"
                label="Repetir a Senha"
                name="rePassword"
                type="password"
                requiredFields={requiredFields}
                value={passData.rePassword || ''}
                formData={passData}
                onChange={e => setPassData({ ...passData, rePassword: e.target.value })}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container justifyContent={'flex-end'} spacing={1}>
                <Grid item>
                  <Button 
                    onClick={handleClose} 
                    title={'Cancelar'} 
                    variant='outlined'
                  />
                </Grid>

                <Grid item>
                  <Button 
                    variant='contained' 
                    onClick={() => { handleClose(); onSubmit(passData); }} 
                    title={'Confirmar'} 
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}