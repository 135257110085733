import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Divider, Icon, List, Tooltip, Zoom } from '@mui/material';
import { useNavigate } from 'react-router-dom';

let menuItems = [
  {
    title: 'Resumo',
    icon: 'home',
    link: '/admin'
  },
  {
    title: 'Unidades',
    icon: 'apartment',
    link: '/admin/unity'
  },
  {
    title: 'Pessoas',
    icon: 'app_registration',
    link: '/admin/people'
  },
  {
    title: '-'
  },
  {
    title: 'Acessos',
    icon: 'list',
    link: '/admin/access'
  },  
  {
    title: '-'
  },
  {
    title: 'Configurações',
    icon: 'settings',
    link: '/admin/settings/profile'
  },

]


export default function AdminMenu(props) {
  let { open = false, setSelected = () => {}, toggleDrawer = () => {}} = props;
  let navigate = useNavigate();

  React.useEffect(() => {
    setSelected(menuItems.find(f => f.link == window.location.pathname) ? menuItems.find(f => f.link == window.location.pathname).title : '')
  }, [setSelected])

  return (
    <List component="nav">
      {menuItems.map((m, i) => {
        return m.title == '-' ? <Divider key={i} style={{margin: 5}}/> :
          <Tooltip
            TransitionComponent={Zoom}
            placement="right"
            arrow
            title={!open ? m.title : ''}
            key={i}
          >
            <ListItemButton
              selected={window.location.pathname.split('/')[2] == m.link.split('/')[2]}
              onClick={() => { navigate(m.link); setSelected(m.title); toggleDrawer(false) }}
              key={i}
            >
              <ListItemIcon style={{minWidth: '45px'}}>
                <Icon
                  color={window.location.pathname.split('/')[2] == m.link.split('/')[2] ? 'primary' : 'inherit'}
                >
                  {m.icon}
                </Icon>
              </ListItemIcon>
              <ListItemText primary={m.title} />
            </ListItemButton>
          </Tooltip>

      })}
    </List>
  )
};
