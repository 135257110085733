import { Grid } from '@mui/material';
import React from 'react';
import Select from '../../Components/Form/Select';
import TextField from '../../Components/Form/TextField';

export default function GateDetail(props) {
  const { unityData = { gates: [] }, setUnityData = () => { }, gate = {}, requiredFields = [] } = props;

  const setGate = () => {
    gate.updated = true;
    setUnityData({ ...unityData })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm >
        <TextField
          id="gates.*.name"
          label="Nome"
          name="name"
          autoComplete="name"
          variant="standard"
          autoFocus
          requiredFields={requiredFields}
          value={gate.name || ''}
          onChange={e => { gate.name = e.target.value; setGate() }}
          // onChange={m => setUnityData({ ...unityData, name: m.target.value })}
        />
      </Grid>

      <Grid item xs={12} sm={'auto'}  >
          <Select
            id="gates.*.direction"
            name="direction"
            label="Direção"
            variant="standard"
            value={gate.direction || ''}
            requiredFields={requiredFields}
            values={ [ { label: 'Entrada', value: 'in' }, { label: 'Saída', value: 'out' } ] }
            onChange={e => { gate.direction = e.target.value; setGate() }}
          />
      </Grid>

      <Grid item xs={12} sm={'auto'}>
        <TextField
          id="gates.*.ip_address"
          label="IP"
          name="ip_address"
          autoComplete="ip_address"
          variant="standard"
          requiredFields={requiredFields}
          value={gate.ip_address || ''}
          onChange={e => { gate.ip_address = e.target.value; setGate() }}
        />
      </Grid>

      <Grid item xs={12} sm={'auto'} >
        <TextField
          id="gates.*.user"
          label="Usuário"
          name="user"
          autoComplete="user"
          variant="standard"
          requiredFields={requiredFields}
          value={gate.gate_user || ''}
          onChange={e => { gate.gate_user = e.target.value; setGate() }}
        />
      </Grid>

      <Grid item xs={12} sm={'auto'} >
        <TextField
          id="gates.*.password"
          label="Senha"
          name="password"
          autoComplete="password"
          variant="standard"
          requiredFields={requiredFields}
          value={gate.gate_password || ''}
          onChange={e => { gate.gate_password = e.target.value; setGate() }}
        />
      </Grid>

    </Grid>
  )
}
