import { Avatar, Chip, Divider, Grid, Icon, IconButton, InputAdornment, Pagination, Paper, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';
import api from '../../../api';
import { toast } from 'react-toastify';
import Button from '../../Components/Form/Button';
import ConfirmDialog from '../../Components/Dialog/ConfirmDialog';
import RowCollapse from '../../Components/Collapse/RowCollapse';
import TextField from '../../Components/Form/TextField';
import ConfigDetail from './ConfigDetail';
import useDebounce from '../../../utils/useDebounce';

export default function ConfigPage() {

  const [config, setConfig] = useState([]);
  const [page, setPage] = useState({ page: 1, total: 1 });
  const [filter, setFilter] = useState({});

  const debounceSearch = useDebounce(filter, 500);
  useEffect(() => {
    getData()
  }, [debounceSearch])

  const getData = (offset = 0, limit = 50) => {
    api.get('config', {
      params: {
        offset, limit, ...filter
      }
    })
      .then(ret => {
        setConfig(ret.list);
        setPage({ total: Math.ceil(ret.total / limit), offset, limit, page: (offset / limit) + 1 })
      })
      .catch(err => {
        toast.error(err)
      })
  }

  useEffect(() => {
    getData()
  }, [])

  const handlePage = (e, value) => {
    getData((value - 1) * page.limit, page.limit);
  }

  const deleteConfig = (config) => {
    api.delete(`config/${config.id}`)
      .then(() => {
        getData(page.offset, page.limit);
        toast.success('Configuração excluida com sucesso');
      })
      .catch(err => {
        toast.error(err);
      })
  }

  return (
    <Grid container style={{ padding: 10 }}>
      <Grid item xs style={{ marginBottom: 10 }}>
        <Grid container justifyContent={'flex-end'} spacing={1} alignItems={'flex-end'} >
          <Grid item xs={12} md={2} style={{ marginRight: 10 }}>
            <form onSubmit={(e) => { e.preventDefault(); getData() }}>
              <TextField
                id="search"
                variant="standard"
                autoFocus
                label="Buscar"
                name="search"
                autoComplete="search"
                value={filter.search}
                onChange={e => { setFilter({ ...filter, search: e.target.value }) }}

                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton
                      type='submit'
                      edge="end"
                    >
                      <Icon>search</Icon>
                    </IconButton>
                  </InputAdornment>
                }}
                style={{ margin: 0 }}
              />
            </form>

          </Grid>

          <Grid item >
            <Button
              variant="contained"
              title="Adicionar"
              endIcon={
                <Icon>
                  add
                </Icon>
              }
              onClick={() => { setConfig([{ id: 'new' }, ...config]) }}
            />
          </Grid>

        </Grid>
      </Grid>

      <Grid item xs={12} >
        <Divider />
      </Grid>
      {
        config.map((m) => {
          return (
            <Grid item xs={12} key={m}>
              <Grid container key={m}>
                <Grid item xs={12} key={m}> 
                  <RowCollapse key={m} collapsed={(<ConfigDetail configId={m.id} onSubmit={() => { getData() }} key={m} />)} open={m.id == 'new'} >
                    <Grid container justifyContent={'space-between'} alignItems={'center'} spacing={4} style={{ paddingTop: 8, paddingBottom: 8 }}>
                      <Grid item xs>
                        <Grid container alignItems={'center'} spacing={2}>

                          <Grid item xs={6} md={3}>
                            <p style={{ margin: 0, fontWeight: 'bold' }}>Type</p>
                            {m.type}
                          </Grid>

                          <Grid item xs md={3}>
                            <p style={{ margin: 0, fontWeight: 'bold' }}>Chave</p>
                            {m.key}
                          </Grid>

                          <Grid item xs={12} sm>
                            <p style={{ margin: 0, fontWeight: 'bold' }}>Valor</p>
                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', margin: 0 }}>{m.value}</p>
                          </Grid>

                        </Grid>
                      </Grid>

                      <Grid item>

                        <ConfirmDialog onDelete={() => deleteConfig(m)} />
                      </Grid>

                    </Grid>
                  </RowCollapse>
                </Grid>

                <Grid item xs={12} key={m.key}>
                  <Divider />
                </Grid>
              </Grid>
            </Grid>
          )
        })
      }

      <Grid item xs={12}>
        <Grid container justifyContent={'center'} style={{ marginTop: 10 }}>
          <Pagination count={page.total} page={page.page} onChange={handlePage} />
        </Grid>
      </Grid>
    </Grid>
  )
}