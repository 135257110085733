import { Divider, Tooltip, Grid, Icon, IconButton, Pagination, Paper, Switch, Typography, LinearProgress, InputAdornment } from '@mui/material';
import React, { useState, useEffect } from 'react';
import api from '../../../api';
import { toast } from 'react-toastify';
import ButtonDefault from '../../Components/Form/Button';
import { maskCep, maskPhone } from '../../../utils/masks';
import ConfirmDialog from '../../Components/Dialog/ConfirmDialog';
import TextField from '../../Components/Form/TextField';
import { useNavigate } from 'react-router-dom';
import useDebounce from '../../../utils/useDebounce';
import Select from '../../Components/Form/Select';
import capitalizeName from '../../Components/CapitalizeName/capitalizeName';

export function UnityList() {
  const [unityData, setUnityData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({ active: 'both' });

  const debounceSearch = useDebounce(filter, 500);
  useEffect(() => {
    getData()
  }, [debounceSearch])

  let navigate = useNavigate();

  const [page, setPage] = useState({ page: 1, total: 1 });

  const getData = (offset = 0, limit = 50) => {
    setIsLoading(true);

    api.get('unity', {
      params: {
        offset, limit, ...filter
      }
    })
      .then((data) => {
        setUnityData(data.list);

        setPage({ total: Math.round(data.total / limit), offset, limit, page: (offset / limit) + 1 })
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setIsLoading(false);
      })
  }

  useEffect(() => {
    getData();
  }, [])

  const deleteUnity = (peopleId) => {
    api.delete(`unity/${peopleId}`)
      .then(() => { getData(); toast.success('Unidade deletada com sucesso!'); })
      .catch(toast.error)
  }

  const handlePage = (e, value) => {
    getData((value - 1) * page.limit, page.limit);
  }

  const setStatus = (unity = {}) => {
    api.put('unity', { ...unity, active: !unity.active })
      .then(() => { getData(); toast.success('Status atualizado') })
      .catch(toast.error)
  }

  return (
    <Paper style={{ overflow: 'hidden' }}>
      {isLoading && <LinearProgress />}

      <Grid container  style={{paddingLeft: 10, paddingRight: 10}}>
        <Grid item xs={12}>
          <form onSubmit={(e) => { e.preventDefault(); getData() }}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Grid container  alignItems={'flex-end'} columnSpacing={2} rowSpacing={1}>
              <Grid item xs={12} sm={12} md>
                <Typography component="h2" variant="h6" color="primary" paddingTop={1.2}>
                  Cadastro de Unidades
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={'auto'} >
                <Select
     
                  allowNull={false}
                  id="active"
                  name="active"
                  label="Status"
                  variant="standard"
                  value={filter.active || ''}
                  values={[{ label: 'Ativo', value: 'true' }, { label: 'Inativo', value: 'false' }, { label: 'Todos', value: 'both' }]}
                  onChange={e => { setFilter({ ...filter, active: e.target.value }) }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={'auto'} >
                <TextField
                  style={{ marginBottom: 0 }}
                  id="search"
                  variant="standard"
                  autoFocus
                  label="Buscar"
                  name="search"
                  autoComplete="search"
                  value={filter.search}
                  onChange={e => { setFilter({ ...filter, search: e.target.value }) }}

                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <IconButton
                        type='submit'
                        edge="end"
                      >
                        <Icon>search</Icon>
                      </IconButton>
                    </InputAdornment>
                  }}
                />

              </Grid>

              <Grid item xs={12} sm={12} md={'auto'} >
                <ButtonDefault
                  onClick={() => { navigate(`/admin/unity/new`); }}
                  variant="contained"
                  title="Adicionar"
                  endIcon={
                    <Icon>
                      add
                    </Icon>
                  }
                />
              </Grid>
            </Grid>
          </form>
        </Grid>

        <Grid item xs={12} style={{ paddingTop: 10 }}>
          <Divider />
        </Grid>

        {
          unityData.map((m, i) => {
            return (
              <Grid item xs={12} key={i}>

                <Grid container justifyContent={'space-between'} alignItems={'center'} spacing={2} style={{ paddingTop: 8, paddingBottom: 8 }}>

                  <Grid item xs>

                    <Grid container style={{ alignItems: 'center', paddingLeft: 5 }}>

                      <Grid item md={3} xs={12}>
                        <p style={{ fontSize: 13, margin: 0, fontWeight: 'bold', color: '#343434' }}>Nome:</p>{capitalizeName(m.name.substring())}
                      </Grid>

                      <Grid item md={5} xs={12}>
                        <p style={{ fontSize: 13, margin: 0, fontWeight: 'bold', color: '#343434' }}>Endereço:</p> {`${m.address}, ${m.city}, ${m.state}, ${maskCep(m.zip)}`}
                      </Grid>

                      <Grid item md={2} xs={12}>
                        <p style={{ fontSize: 13, margin: 0, fontWeight: 'bold', color: '#343434' }}>Telefone:</p> {`${maskPhone(m.phone)}`}
                      </Grid>

                      <Grid item md={2} xs={12}>
                        {<Switch checked={m.active} onChange={() => { setStatus(m) }} />}
                        {m.active}
                      </Grid>

                    </Grid>

                  </Grid>

                  <Grid item>
                    <IconButton onClick={() => { navigate(`/admin/unity/${m.id}`); }}>
                      <Tooltip title="Editar" arrow>
                        <Icon>edit</Icon>
                      </Tooltip>
                    </IconButton>
                    <ConfirmDialog onDelete={() => { deleteUnity(m.id) }} />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>

              </Grid>
            )
          })
        }

        <Grid item xs={12}>
          <Grid container justifyContent={'center'} style={{ marginTop: 10 }}>
            <Pagination count={page.total} page={page.page} onChange={handlePage} />
          </Grid>
        </Grid>

      </Grid>

    </Paper >
  )
}
