import React, { useRef, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import logo from './../../Images/laisLogo.png';
import Copyright from './../Components/UI/Copyright';
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import SHA256 from 'crypto-js/sha256';
import { CircularProgress, Grid, IconButton, InputAdornment } from '@mui/material';
import { toast } from 'react-toastify';
import TextField from '../Components/Form/TextField';
import Button from '../Components/Form/Button';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoginGoogle from '../Components/GoogleLogin/LoginGoogle';
import ReCAPTCHA from "react-google-recaptcha";

export default function Login() {

  let navigate = useNavigate();
  const [loginData, setLoginData] = useState({});
  const [loading, setLoading] = useState(false);
  const [verfied, setVerifed] = useState(false)
  const [showReCaptcha, setShowReCaptcha] = useState(sessionStorage.getItem('showRecaptcha') == 'true');
  const [showPassword, setShowPassword] = useState(false);
  const captchaRef = useRef(null);

  function onChange() {
    setVerifed(true);
  }


  const doLogin = (google) => {
    setLoading(true);

    api.post('auth/login', { ...loginData, password: (SHA256(loginData.password)).toString(), google })
      .then(ret => {
        localStorage.setItem('user', JSON.stringify(ret))
        navigate('/admin');
        sessionStorage.setItem('showRecaptcha', 'false');
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        toast.error(err);
        // setShowReCaptcha(true);
        // sessionStorage.setItem('showRecaptcha', 'true');
        // setVerifed(false);
        captchaRef.current.props.grecaptcha.reset();
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    doLogin()
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img
          style={{ height: 100, marginBottom: 50 }}
          src={logo}
          alt={'logo'}
        />

        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate sx={{ mt: 1 }}
        >
          <TextField
            autoFocus
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            Autocomplete="email"
            InputLabelProps={{
              shrink: true,
            }}
            value={loginData.mail || ''}
            onChange={e => setLoginData({ ...loginData, mail: e.target.value })}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            Autocomplete="password"
            name="password"
            label="Senha"
            InputLabelProps={{
              shrink: true,
            }}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={e => setShowPassword(!showPassword)}
                  onMouseDown={e => e.preventDefault()}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }}
            id="password"
            textContentType="password"
            // autoComplete="current-password"
            value={loginData.password || ''}
            onChange={e => setLoginData({ ...loginData, password: e.target.value })}
          />

          <Grid container >
            <Grid item xs={8}>
              <FormControlLabel
                style={{ marginLeft: 0 }}
                control={
                  <Checkbox
                    style={{ padding: 0, marginRight: 10, marginBottom: 1 }}
                    value="remember"
                    color="primary"
                    onChange={e => setLoginData({ ...loginData, remember: e.target.value })}
                  />
                }
                label="Manter logado"
              />
            </Grid>
            <Grid item xs={4} style={{ paddingLeft: 16 }}>
              <Button
                title="Recuperar Senha"
                onClick={() => { navigate('/resetpassword') }}>
              </Button>
            </Grid>
          </Grid>

          <Grid container justifyContent={'center'}>
            {
              showReCaptcha &&
              <ReCAPTCHA
                ref={captchaRef}
                sitekey="6LcOVNQjAAAAANyJlRcBuWmc4qTdzWnbodSQ-D4y"
                onChange={onChange}
              />
            }
          </Grid>

          <Button
            id="button_submit"
            type="submit"
            fullWidth
            size='default'
            variant="contained"
            // disabled={loading}
            disabled={loading || (showReCaptcha && !verfied)}
            sx={{ mt: 3, mb: 2 }}
            title={<>Logar
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </>
            }
          />


        </Box>
      </Box>

      {/* <Grid item style={{ marginBottom: 20 }}>
        <LoginGoogle onLogin={(res) => { doLogin(res) }} />
      </Grid> */}
      {/* Lais2022*! */}
      <Copyright />
    </Container>
  );
}

