export const onlyNumber = (v) => {
  return v ? v.replace(/\D/g, '') : ''
}

export const maskPhone = (v) => {
  let r = onlyNumber(v);
  r = r.replace(/^0/, "");
  if (r.length > 10) {
    r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
  } else if (r.length > 6) {
    r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
  } else if (r.length > 2) {
    r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
  } else if (r.length > 1) {
    r = r.replace(/^(\d*)/, "($1");
  }
  return r ? r : '';
}

export const maskCep = (v, autoPad = true) => {

  let cepNumber = onlyNumber(v);
  return ((cepNumber.length > 5) ?
    cepNumber.padStart(autoPad ? 8 : 0, '0').slice(0, 5) + "-" + cepNumber.padStart(autoPad ? 8 : 0, '0').slice(5) :
    cepNumber).slice(0, 9);
}

