import React, { useEffect, useState } from 'react';
import api from '../../../api';
import ButtonDefault from '../../Components/Form/Button';
import { Grid, Icon, LinearProgress, Pagination, Typography } from "@mui/material";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import DataRange from '../../Components/DataRange/DataRange';
import { Input } from 'antd';
import Button from '../../Components/Form/Button';
import { toast } from 'react-toastify';
import ConfirmDialog from '../../Components/Dialog/ConfirmDialog';
import useDebounce from '../../../utils/useDebounce';

export default function LogsFilter(props) {
  const { onFilter = () => { } } = props;
  const [filter, setFilter] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [logsFilters, setLogsFilters] = useState([]);

  const deleteLog = () => {
    api.delete(`logs`)
      .then(() => {
        toast.success('Lista de logs deletado com sucesso!');
        getData()
      })
      .catch(err => {
        toast.error(err);
      })

  }


  const debounceSearch = useDebounce(filter, 500);
  useEffect(() => {
    getData()
  }, [debounceSearch])

  const [page, setPage] = useState({ page: 1, total: 1 });

  const getData = (offset = 0, limit = 50) => {
    setIsLoading(true);

    api.get('logs', {
      params: {
        offset, limit, ...filter
      }
    })
      .then((data) => {
        setLogsFilters(data.list);

        setPage({ total: Math.round(data.total / limit), offset, limit, page: (offset / limit) + 1 })
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setIsLoading(false);
      })
  }

  useEffect(() => {
    getData();
  }, [])

  const handlePage = (e, value) => {
    getData((value - 1) * page.limit, page.limit);
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    onFilter(filter);
  }

  return (

    <form onSubmit={handleSubmit} >
      {isLoading && <LinearProgress />}

      <Grid container alignItems={'flex-end'} style={{ padding: 20 }}>

        <Grid item xs={12}>
          <Typography component="h4" variant="h10" color="primary">
            Filtros
          </Typography>
        </Grid>

        <Grid container alignItems={'center'} spacing={2} >
          <Grid item xs={12} md={'auto'} alignItems={'center'} >
            <Input
              style={{ height: 40 }}
              placeholder='Nome'
              id="source"
              name="source"
              autoComplete="source"
              value={filter.source || ''}
              onChange={e => { setFilter({ ...filter, source: e.target.value }) }}
            />
          </Grid>
          <Grid item xs={12} md={'auto'} alignItems={'center'} >
            <Input
              style={{ height: 40 }}
              placeholder='Mensagem'
              id="message"
              name="search"
              autoComplete="search"
              value={filter.message || ''}
              onChange={e => { setFilter({ ...filter, message: e.target.value }) }}
            />
          </Grid>

          <Grid item xs={12} md={4} xl={3}>
            <DataRange
              id="Data"
              autoFocus
              label="Período"
              name="search"
              onChange={(date, dateString) => {
                setFilter({ ...filter, dateStart: dateString[0], dateEnd: dateString[1] })
              }}
            />
          </Grid>

          <Grid item >
            <ButtonDefault
              sx={{ height: 39, fontSize: 14 }}
              type='submit'
              variant="contained"
              title="Filtrar"
              endIcon={<Icon>search</Icon>}
            />
          </Grid>

          {/* <Grid item >
            <Button
              style={{ width: 100, height: 39, background: '#1976d2', color: '#fff' }}
              title='Limpar'
              deleteLog
            />
          </Grid> */}
          <Grid item >
            <ConfirmDialog
              onDelete={() => deleteLog(logsFilters)} buttonTitle={'Limpar Logs'}
            />
          </Grid>
        </Grid>

      </Grid>
    </form>
  )
}
