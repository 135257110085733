
import * as React from 'react';

import Box from '@mui/material/Box';
import { Fab, Icon } from '@mui/material';

export function ScrollTop(props) {


  const { reference } = props;

  const [pos, setPos] = React.useState(false);

  const handleTop = () => {
    reference.current.scrollTop = 0;
    setPos(false);
  };

  const handleScroll = () => {
    if (reference.current.scrollTop > 50) {
      setPos(true);
    } else {
      setPos(false);
    }
  };

  React.useEffect(() => {
    if (reference && reference.current) {
      const temp = reference.current;
      temp.addEventListener("scroll", handleScroll);
      return () => temp.removeEventListener("scroll", handleScroll);
  
    }
  }, [reference]);


  return (


      <Box
        onClick={handleTop}
        role="presentation"
        sx={{ 
          position: 'fixed', 
          bottom: 25, 
          right: 25 , 
          display: pos ? "block" : "none",
          zIndex: 999
        }}
      >
        <Fab size="small" aria-label="scroll back to top" >
          <Icon>keyboard_arrow_up</Icon>
        </Fab>
      </Box>
  );
}